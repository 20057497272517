import './index.css';
import 'src/locale/i18n';
import './fonts/Gilroy-Black.ttf';
import './fonts/Gilroy-Bold.ttf';
import './fonts/Gilroy-ExtraBold.ttf';
import './fonts/Gilroy-Heavy.ttf';
import './fonts/Gilroy-Light.ttf';
import './fonts/Gilroy-Medium.ttf';
import './fonts/Gilroy-Regular.ttf';
import './fonts/Gilroy-SemiBold.ttf';
import './fonts/Gilroy-Thin.ttf';
import './fonts/Gilroy-UltraLight.ttf';

import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { App } from './App';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

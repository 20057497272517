/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasicModel } from './BasicModel';
import {
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';

/**
 * 
 * @export
 * @interface EquipmentGroupListModel
 */
export interface EquipmentGroupListModel {
    /**
     * 
     * @type {number}
     * @memberof EquipmentGroupListModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentGroupListModel
     */
    name?: string | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof EquipmentGroupListModel
     */
    equipments?: Array<BasicModel> | null;
}

/**
 * Check if a given object implements the EquipmentGroupListModel interface.
 */
export function instanceOfEquipmentGroupListModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EquipmentGroupListModelFromJSON(json: any): EquipmentGroupListModel {
    return EquipmentGroupListModelFromJSONTyped(json, false);
}

export function EquipmentGroupListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentGroupListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'equipments': !exists(json, 'equipments') ? undefined : (json['equipments'] === null ? null : (json['equipments'] as Array<any>).map(BasicModelFromJSON)),
    };
}

export function EquipmentGroupListModelToJSON(value?: EquipmentGroupListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'equipments': value.equipments === undefined ? undefined : (value.equipments === null ? null : (value.equipments as Array<any>).map(BasicModelToJSON)),
    };
}


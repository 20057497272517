/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RateViewModel } from './RateViewModel';
import {
    RateViewModelFromJSON,
    RateViewModelFromJSONTyped,
    RateViewModelToJSON,
} from './RateViewModel';

/**
 * 
 * @export
 * @interface FinancialCompanyModel
 */
export interface FinancialCompanyModel {
    /**
     * 
     * @type {number}
     * @memberof FinancialCompanyModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancialCompanyModel
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FinancialCompanyModel
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<RateViewModel>}
     * @memberof FinancialCompanyModel
     */
    rates?: Array<RateViewModel> | null;
    /**
     * 
     * @type {Date}
     * @memberof FinancialCompanyModel
     */
    updatedOn?: Date;
}

/**
 * Check if a given object implements the FinancialCompanyModel interface.
 */
export function instanceOfFinancialCompanyModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FinancialCompanyModelFromJSON(json: any): FinancialCompanyModel {
    return FinancialCompanyModelFromJSONTyped(json, false);
}

export function FinancialCompanyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialCompanyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'rates': !exists(json, 'rates') ? undefined : (json['rates'] === null ? null : (json['rates'] as Array<any>).map(RateViewModelFromJSON)),
        'updatedOn': !exists(json, 'updatedOn') ? undefined : (new Date(json['updatedOn'])),
    };
}

export function FinancialCompanyModelToJSON(value?: FinancialCompanyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'isActive': value.isActive,
        'rates': value.rates === undefined ? undefined : (value.rates === null ? null : (value.rates as Array<any>).map(RateViewModelToJSON)),
        'updatedOn': value.updatedOn === undefined ? undefined : (value.updatedOn.toISOString()),
    };
}


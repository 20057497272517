/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Equipment } from './Equipment';
import {
    EquipmentFromJSON,
    EquipmentFromJSONTyped,
    EquipmentToJSON,
} from './Equipment';

/**
 * 
 * @export
 * @interface EquipmentGroup
 */
export interface EquipmentGroup {
    /**
     * 
     * @type {Date}
     * @memberof EquipmentGroup
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EquipmentGroup
     */
    updatedOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof EquipmentGroup
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EquipmentGroup
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EquipmentGroup
     */
    rank?: number;
    /**
     * 
     * @type {Array<Equipment>}
     * @memberof EquipmentGroup
     */
    equipments?: Array<Equipment> | null;
}

/**
 * Check if a given object implements the EquipmentGroup interface.
 */
export function instanceOfEquipmentGroup(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EquipmentGroupFromJSON(json: any): EquipmentGroup {
    return EquipmentGroupFromJSONTyped(json, false);
}

export function EquipmentGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdOn': !exists(json, 'createdOn') ? undefined : (new Date(json['createdOn'])),
        'updatedOn': !exists(json, 'updatedOn') ? undefined : (new Date(json['updatedOn'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'equipments': !exists(json, 'equipments') ? undefined : (json['equipments'] === null ? null : (json['equipments'] as Array<any>).map(EquipmentFromJSON)),
    };
}

export function EquipmentGroupToJSON(value?: EquipmentGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdOn': value.createdOn === undefined ? undefined : (value.createdOn.toISOString()),
        'updatedOn': value.updatedOn === undefined ? undefined : (value.updatedOn.toISOString()),
        'id': value.id,
        'name': value.name,
        'rank': value.rank,
        'equipments': value.equipments === undefined ? undefined : (value.equipments === null ? null : (value.equipments as Array<any>).map(EquipmentToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SellerCompanyDetailModel
 */
export interface SellerCompanyDetailModel {
    /**
     * 
     * @type {number}
     * @memberof SellerCompanyDetailModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerCompanyDetailModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SellerCompanyDetailModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SellerCompanyDetailModel
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SellerCompanyDetailModel
     */
    contactPhone?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SellerCompanyDetailModel
     */
    equipmentIds?: Array<number> | null;
}

/**
 * Check if a given object implements the SellerCompanyDetailModel interface.
 */
export function instanceOfSellerCompanyDetailModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SellerCompanyDetailModelFromJSON(json: any): SellerCompanyDetailModel {
    return SellerCompanyDetailModelFromJSONTyped(json, false);
}

export function SellerCompanyDetailModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerCompanyDetailModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'contactName': !exists(json, 'contactName') ? undefined : json['contactName'],
        'contactPhone': !exists(json, 'contactPhone') ? undefined : json['contactPhone'],
        'equipmentIds': !exists(json, 'equipmentIds') ? undefined : json['equipmentIds'],
    };
}

export function SellerCompanyDetailModelToJSON(value?: SellerCompanyDetailModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'contactName': value.contactName,
        'contactPhone': value.contactPhone,
        'equipmentIds': value.equipmentIds,
    };
}


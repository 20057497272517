/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasicModel } from './BasicModel';
import {
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';

/**
 * 
 * @export
 * @interface OfferGuestViewModel
 */
export interface OfferGuestViewModel {
    /**
     * 
     * @type {BasicModel}
     * @memberof OfferGuestViewModel
     */
    financialCompany?: BasicModel;
    /**
     * 
     * @type {number}
     * @memberof OfferGuestViewModel
     */
    monthlyPaymentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferGuestViewModel
     */
    totalPaymentValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OfferGuestViewModel
     */
    isAccepted?: boolean;
}

/**
 * Check if a given object implements the OfferGuestViewModel interface.
 */
export function instanceOfOfferGuestViewModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OfferGuestViewModelFromJSON(json: any): OfferGuestViewModel {
    return OfferGuestViewModelFromJSONTyped(json, false);
}

export function OfferGuestViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferGuestViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'financialCompany': !exists(json, 'financialCompany') ? undefined : BasicModelFromJSON(json['financialCompany']),
        'monthlyPaymentValue': !exists(json, 'monthlyPaymentValue') ? undefined : json['monthlyPaymentValue'],
        'totalPaymentValue': !exists(json, 'totalPaymentValue') ? undefined : json['totalPaymentValue'],
        'isAccepted': !exists(json, 'isAccepted') ? undefined : json['isAccepted'],
    };
}

export function OfferGuestViewModelToJSON(value?: OfferGuestViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'financialCompany': BasicModelToJSON(value.financialCompany),
        'monthlyPaymentValue': value.monthlyPaymentValue,
        'totalPaymentValue': value.totalPaymentValue,
        'isAccepted': value.isAccepted,
    };
}


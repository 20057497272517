import { Theme } from 'src/types/theme';
import { colors } from './colors';

export const themeLight: Theme = {
  colors: {
    color1: colors.white,
    color2: colors.black,
    color3: colors.gray,
    color4: colors.lightBlue,
    color5: colors.darkBlue,
    color6: colors.green,
    color7: colors.gainsboro,
    color8: colors.nobel,
    color9: colors.error
  }
};

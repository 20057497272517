import React from 'react';
import { View } from 'src/components/view';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { layoutStyles } from 'src/styles/layoutStyles';
import { LayoutContainerProps } from '.';

export const LayoutContainer = (props: LayoutContainerProps) => {
  const { style, children, ...remainingProps } = props;
  const { width } = useWindowDimensions();
  const childrenAny = children as any;
  let cStyle = childrenAny?.props?.style;
  let customStyle: any = { maxWidth: Math.min(width, 1440), flex: 1 };

  if (Array.isArray(childrenAny?.props?.style)) {
    cStyle = childrenAny?.props?.style.reduce((acc: any, curr: any) => {
      return { ...acc, ...curr };
    }, {});
  }

  cStyle = {
    ...cStyle,
    ...customStyle
  };

  return (
    <View
      style={[
        layoutStyles.justifyCenter,
        cStyle.backgroundColor && {
          backgroundColor: cStyle.backgroundColor
        },
        style
      ]}
      {...remainingProps}
    >
      {React.cloneElement(childrenAny, {
        ...childrenAny?.props,
        style: cStyle
      })}
    </View>
  );
};

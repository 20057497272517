import { UseStyleVariables } from 'src/hooks/useStyles';
import { layoutStyles, padding } from 'src/styles/layoutStyles';

export const baseStyles = (useStyleVariables: UseStyleVariables) => {
  const { theme } = useStyleVariables;
  return {
    container: {
      border: 0,
      cursor: 'pointer',
      width: 'fit-content',
      borderRadius: 10,
      backgroundColor: theme.colors.color4,
      height: 52,
      ...padding(8, 'horizontal'),
      ...layoutStyles.center
    }
  };
};

import { ConfigProvider, Input } from 'antd';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTheme } from 'src/hooks/useTheme';
import { layoutStyles, margin } from 'src/styles/layoutStyles';
import { CustomInputProps } from '.';
import { ErrorText } from '../errorText';
import { Icon } from '../icon';
import { View } from '../view';
import './input.css';

export const CustomInput = (props: CustomInputProps) => {
  const { style, value, type, placeholder = '', onTextChange, register, name = '', hasError, errorMessage, rightIcon } = props;
  const baseOnChange = props.onChange;
  const theme = useTheme();

  const { onChange, ...remaininRegisterFields } = register?.(name) || ({} as UseFormRegisterReturn);
  return (
    <View style={[layoutStyles.fullFlex, layoutStyles.column, { position: 'relative' }]}>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              controlHeightLG: 48
            }
          }
        }}
      >
        <Input
          size="large"
          {...props}
          suffix={
            rightIcon && (
              <Icon
                color={theme.colors.color4}
                size={25}
                icon={rightIcon}
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '16px'
                }}
              />
            )
          }
          type={type}
          style={{
            ...(hasError ? { border: `2px solid ${theme.colors.color9}` } : {}),
            ...style
          }}
          value={value}
          onChange={(e) => {
            baseOnChange?.(e);
            onChange?.(e);
            onTextChange?.(e.target.value);
          }}
          placeholder={placeholder}
          {...remaininRegisterFields}
        />
        <ErrorText errorMessage={errorMessage} style={margin(2, 'top')} />
      </ConfigProvider>
    </View>
  );
};

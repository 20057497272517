/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasicModel } from './BasicModel';
import {
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import type { CurrencyModel } from './CurrencyModel';
import {
    CurrencyModelFromJSON,
    CurrencyModelFromJSONTyped,
    CurrencyModelToJSON,
} from './CurrencyModel';
import type { MaturityOptionModel } from './MaturityOptionModel';
import {
    MaturityOptionModelFromJSON,
    MaturityOptionModelFromJSONTyped,
    MaturityOptionModelToJSON,
} from './MaturityOptionModel';

/**
 * 
 * @export
 * @interface OfferViewModel
 */
export interface OfferViewModel {
    /**
     * 
     * @type {number}
     * @memberof OfferViewModel
     */
    monthlyPaymentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferViewModel
     */
    totalPaymentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferViewModel
     */
    equipmentValue?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferViewModel
     */
    payment?: number;
    /**
     * 
     * @type {MaturityOptionModel}
     * @memberof OfferViewModel
     */
    maturityOption?: MaturityOptionModel;
    /**
     * 
     * @type {boolean}
     * @memberof OfferViewModel
     */
    isAccepted?: boolean;
    /**
     * 
     * @type {BasicModel}
     * @memberof OfferViewModel
     */
    financialCompany?: BasicModel;
    /**
     * 
     * @type {CurrencyModel}
     * @memberof OfferViewModel
     */
    currency?: CurrencyModel;
}

/**
 * Check if a given object implements the OfferViewModel interface.
 */
export function instanceOfOfferViewModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OfferViewModelFromJSON(json: any): OfferViewModel {
    return OfferViewModelFromJSONTyped(json, false);
}

export function OfferViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monthlyPaymentValue': !exists(json, 'monthlyPaymentValue') ? undefined : json['monthlyPaymentValue'],
        'totalPaymentValue': !exists(json, 'totalPaymentValue') ? undefined : json['totalPaymentValue'],
        'equipmentValue': !exists(json, 'equipmentValue') ? undefined : json['equipmentValue'],
        'payment': !exists(json, 'payment') ? undefined : json['payment'],
        'maturityOption': !exists(json, 'maturityOption') ? undefined : MaturityOptionModelFromJSON(json['maturityOption']),
        'isAccepted': !exists(json, 'isAccepted') ? undefined : json['isAccepted'],
        'financialCompany': !exists(json, 'financialCompany') ? undefined : BasicModelFromJSON(json['financialCompany']),
        'currency': !exists(json, 'currency') ? undefined : CurrencyModelFromJSON(json['currency']),
    };
}

export function OfferViewModelToJSON(value?: OfferViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monthlyPaymentValue': value.monthlyPaymentValue,
        'totalPaymentValue': value.totalPaymentValue,
        'equipmentValue': value.equipmentValue,
        'payment': value.payment,
        'maturityOption': MaturityOptionModelToJSON(value.maturityOption),
        'isAccepted': value.isAccepted,
        'financialCompany': BasicModelToJSON(value.financialCompany),
        'currency': CurrencyModelToJSON(value.currency),
    };
}


// Icon.tsx
import IcoMoon from 'react-icomoon';
import { useTheme } from 'src/hooks/useTheme';
import { IconProps } from '.';
import iconSet from './selection.json';

const Icon = (props: IconProps) => {
  const theme = useTheme();
  const { size = 16, ...remaininProps } = props;
  return <IcoMoon iconSet={iconSet} size={size} color={theme.colors.color5} {...remaininProps} />;
};

export { Icon };

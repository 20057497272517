import { t } from 'i18next';
import { useAtom } from 'jotai';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { View } from 'src/components/view';
import { useMediaQueryVariables } from 'src/hooks/useMediaQuery';
import { layoutStyles, margin, padding } from 'src/styles/layoutStyles';
import { menuAtom } from 'src/utils/atoms/menuAtom';
import { toDialFormat, toMailFormat } from 'src/utils/string';
import { MenuProps } from '.';
import { Header } from '../header';
import { headerLabelKeys } from '../header/header.constants';
import { Icon } from '../icon';
import { Typography } from '../typography';

export const Menu = (props: MenuProps) => {
  const [, setIsMenuOpen] = useAtom(menuAtom);
  const { isTabletOrMobile } = useMediaQueryVariables();
  useEffect(() => {
    if (!isTabletOrMobile) {
      setIsMenuOpen(false);
    }
    //eslint-disable-next-line
  }, [isTabletOrMobile]);

  return (
    <View style={[layoutStyles.fullFlex, layoutStyles.column]}>
      <Header />
      <View style={[layoutStyles.column, layoutStyles.center, layoutStyles.spaceBetween, layoutStyles.fullFlex, padding(8, 'vertical')]}>
        <View style={[layoutStyles.column, layoutStyles.center]}>
          {_.map(headerLabelKeys, (item: { label: string; to: string }, index) => {
            return (
              <a
                onClick={() => setIsMenuOpen(false)}
                href={item.to} // Use the absolute path directly
                style={{
                  textDecoration: 'none',
                  ...margin(8, 'top')
                }}
              >
                <Typography size={20} fontFamily="Medium" style={margin(8, 'bottom')} color={'color5'}>
                  {t(item.label)}
                </Typography>
              </a>
            );
          })}
        </View>
        <View style={[layoutStyles.column, layoutStyles.center]}>
          <a style={layoutStyles.justifyCenter} href={toDialFormat(t('contact.phone') as string)}>
            <Icon icon="phone" style={margin(2, 'right')} />
            <Typography>{t('contact.phone')}</Typography>
          </a>
          <a style={{ ...layoutStyles.justifyCenter, ...margin(3, 'top') }} href={toMailFormat(t('contact.email') as string)}>
            <Icon icon="mail" style={margin(2, 'right')} />
            <Typography>{t('contact.email')}</Typography>
          </a>
        </View>
      </View>
    </View>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationFormViewModelPagedDataModel } from './ApplicationFormViewModelPagedDataModel';
import {
    ApplicationFormViewModelPagedDataModelFromJSON,
    ApplicationFormViewModelPagedDataModelFromJSONTyped,
    ApplicationFormViewModelPagedDataModelToJSON,
} from './ApplicationFormViewModelPagedDataModel';
import type { ErrorModel } from './ErrorModel';
import {
    ErrorModelFromJSON,
    ErrorModelFromJSONTyped,
    ErrorModelToJSON,
} from './ErrorModel';

/**
 * 
 * @export
 * @interface ApplicationFormViewModelPagedDataModelValidatedModel
 */
export interface ApplicationFormViewModelPagedDataModelValidatedModel {
    /**
     * 
     * @type {ErrorModel}
     * @memberof ApplicationFormViewModelPagedDataModelValidatedModel
     */
    error?: ErrorModel;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationFormViewModelPagedDataModelValidatedModel
     */
    readonly success?: boolean;
    /**
     * 
     * @type {ApplicationFormViewModelPagedDataModel}
     * @memberof ApplicationFormViewModelPagedDataModelValidatedModel
     */
    data?: ApplicationFormViewModelPagedDataModel;
}

/**
 * Check if a given object implements the ApplicationFormViewModelPagedDataModelValidatedModel interface.
 */
export function instanceOfApplicationFormViewModelPagedDataModelValidatedModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationFormViewModelPagedDataModelValidatedModelFromJSON(json: any): ApplicationFormViewModelPagedDataModelValidatedModel {
    return ApplicationFormViewModelPagedDataModelValidatedModelFromJSONTyped(json, false);
}

export function ApplicationFormViewModelPagedDataModelValidatedModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationFormViewModelPagedDataModelValidatedModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : ErrorModelFromJSON(json['error']),
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': !exists(json, 'data') ? undefined : ApplicationFormViewModelPagedDataModelFromJSON(json['data']),
    };
}

export function ApplicationFormViewModelPagedDataModelValidatedModelToJSON(value?: ApplicationFormViewModelPagedDataModelValidatedModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ErrorModelToJSON(value.error),
        'data': ApplicationFormViewModelPagedDataModelToJSON(value.data),
    };
}


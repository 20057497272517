import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useTheme } from './hooks/useTheme';
import { DashboardView } from './views/DashboardView/DashboardView';
import { LoginView } from './views/LoginView/LoginView';
import { NotFoundView } from './views/NotFoundView/NotFoundView';

import { message } from 'antd';
import { useAtom } from 'jotai';
import { messageAtom } from './utils/atoms/global-atoms';
import { FinancialManagementView } from './views/FinancialManagementView/FinancialManagementView';
import { ReportsView } from './views/ReportsView/ReportsView';
import { SellerCompanyManagementView } from './views/SellerCompanyManagementView/SellerCompanyManagementView';

export const App = () => {
  const theme = useTheme();
  const [toastMessage] = useAtom(messageAtom);
  const router = createBrowserRouter([
    {
      path: '/',
      element: <LoginView />,
      errorElement: <NotFoundView />
    },
    {
      path: '/dashboard',
      element: <DashboardView />,
      errorElement: <NotFoundView />
    },
    {
      path: '/financial-management',
      element: <FinancialManagementView />,
      errorElement: <NotFoundView />
    },
    {
      path: '/seller-management',
      element: <SellerCompanyManagementView />,
      errorElement: <NotFoundView />
    },
    {
      path: '/reports',
      element: <ReportsView />,
      errorElement: <NotFoundView />
    }
  ]);

  useEffect(() => {
    document.documentElement.style.setProperty('--placeholder-color', theme.colors.color5);
    document.documentElement.style.setProperty('--green-text', theme.colors.color6);
    document.documentElement.style.setProperty('--placeholder-opacity', '0.85');
  }, [theme]);

  useEffect(() => {
    if (toastMessage.message === '') return;
    switch (toastMessage.type) {
      case 'error':
        message.error(toastMessage.message);
        break;
      case 'info':
        message.info(toastMessage.message);
        break;
      case 'warning':
        message.warning(toastMessage.message);
        break;
      case 'success':
        message.success(toastMessage.message);
        break;
      case 'loading':
        message.loading(toastMessage.message);
        break;
    }
    toastMessage.message = '';
  }, [toastMessage]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OfferApplicationFormCreateModel } from './OfferApplicationFormCreateModel';
import {
    OfferApplicationFormCreateModelFromJSON,
    OfferApplicationFormCreateModelFromJSONTyped,
    OfferApplicationFormCreateModelToJSON,
} from './OfferApplicationFormCreateModel';
import type { OfferCalculatorRequestModel } from './OfferCalculatorRequestModel';
import {
    OfferCalculatorRequestModelFromJSON,
    OfferCalculatorRequestModelFromJSONTyped,
    OfferCalculatorRequestModelToJSON,
} from './OfferCalculatorRequestModel';

/**
 * 
 * @export
 * @interface ApplicationFormCreateModel
 */
export interface ApplicationFormCreateModel {
    /**
     * 
     * @type {number}
     * @memberof ApplicationFormCreateModel
     */
    equipmentSellerCompanyId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormCreateModel
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormCreateModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormCreateModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormCreateModel
     */
    vkn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormCreateModel
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormCreateModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormCreateModel
     */
    sellerCompanyOther?: string | null;
    /**
     * 
     * @type {OfferCalculatorRequestModel}
     * @memberof ApplicationFormCreateModel
     */
    calculationParameters?: OfferCalculatorRequestModel;
    /**
     * 
     * @type {Array<OfferApplicationFormCreateModel>}
     * @memberof ApplicationFormCreateModel
     */
    offers?: Array<OfferApplicationFormCreateModel> | null;
}

/**
 * Check if a given object implements the ApplicationFormCreateModel interface.
 */
export function instanceOfApplicationFormCreateModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationFormCreateModelFromJSON(json: any): ApplicationFormCreateModel {
    return ApplicationFormCreateModelFromJSONTyped(json, false);
}

export function ApplicationFormCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationFormCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'equipmentSellerCompanyId': !exists(json, 'equipmentSellerCompanyId') ? undefined : json['equipmentSellerCompanyId'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'vkn': !exists(json, 'vkn') ? undefined : json['vkn'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sellerCompanyOther': !exists(json, 'sellerCompanyOther') ? undefined : json['sellerCompanyOther'],
        'calculationParameters': !exists(json, 'calculationParameters') ? undefined : OfferCalculatorRequestModelFromJSON(json['calculationParameters']),
        'offers': !exists(json, 'offers') ? undefined : (json['offers'] === null ? null : (json['offers'] as Array<any>).map(OfferApplicationFormCreateModelFromJSON)),
    };
}

export function ApplicationFormCreateModelToJSON(value?: ApplicationFormCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'equipmentSellerCompanyId': value.equipmentSellerCompanyId,
        'fullName': value.fullName,
        'email': value.email,
        'phone': value.phone,
        'vkn': value.vkn,
        'company': value.company,
        'description': value.description,
        'sellerCompanyOther': value.sellerCompanyOther,
        'calculationParameters': OfferCalculatorRequestModelToJSON(value.calculationParameters),
        'offers': value.offers === undefined ? undefined : (value.offers === null ? null : (value.offers as Array<any>).map(OfferApplicationFormCreateModelToJSON)),
    };
}


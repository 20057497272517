/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipmentGroup } from './EquipmentGroup';
import {
    EquipmentGroupFromJSON,
    EquipmentGroupFromJSONTyped,
    EquipmentGroupToJSON,
} from './EquipmentGroup';

/**
 * 
 * @export
 * @interface Equipment
 */
export interface Equipment {
    /**
     * 
     * @type {Date}
     * @memberof Equipment
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Equipment
     */
    updatedOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof Equipment
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Equipment
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Equipment
     */
    rank?: number;
    /**
     * 
     * @type {number}
     * @memberof Equipment
     */
    equipmentGroupId?: number;
    /**
     * 
     * @type {EquipmentGroup}
     * @memberof Equipment
     */
    equipmentGroup?: EquipmentGroup;
}

/**
 * Check if a given object implements the Equipment interface.
 */
export function instanceOfEquipment(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EquipmentFromJSON(json: any): Equipment {
    return EquipmentFromJSONTyped(json, false);
}

export function EquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Equipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdOn': !exists(json, 'createdOn') ? undefined : (new Date(json['createdOn'])),
        'updatedOn': !exists(json, 'updatedOn') ? undefined : (new Date(json['updatedOn'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'equipmentGroupId': !exists(json, 'equipmentGroupId') ? undefined : json['equipmentGroupId'],
        'equipmentGroup': !exists(json, 'equipmentGroup') ? undefined : EquipmentGroupFromJSON(json['equipmentGroup']),
    };
}

export function EquipmentToJSON(value?: Equipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdOn': value.createdOn === undefined ? undefined : (value.createdOn.toISOString()),
        'updatedOn': value.updatedOn === undefined ? undefined : (value.updatedOn.toISOString()),
        'id': value.id,
        'name': value.name,
        'rank': value.rank,
        'equipmentGroupId': value.equipmentGroupId,
        'equipmentGroup': EquipmentGroupToJSON(value.equipmentGroup),
    };
}


import { Table, TableProps } from 'antd';
import styles from './data-table.module.css';

export type DataTableProps = {} & TableProps<any>;

const DataTable = (props: DataTableProps) => {
  return <Table {...props} className={styles.dataTable} />;
};

export default DataTable;

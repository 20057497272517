/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipmentGroupCountModel } from './EquipmentGroupCountModel';
import {
    EquipmentGroupCountModelFromJSON,
    EquipmentGroupCountModelFromJSONTyped,
    EquipmentGroupCountModelToJSON,
} from './EquipmentGroupCountModel';

/**
 * 
 * @export
 * @interface SellerCompanyListModel
 */
export interface SellerCompanyListModel {
    /**
     * 
     * @type {number}
     * @memberof SellerCompanyListModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SellerCompanyListModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SellerCompanyListModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SellerCompanyListModel
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SellerCompanyListModel
     */
    contactPhone?: string | null;
    /**
     * 
     * @type {Array<EquipmentGroupCountModel>}
     * @memberof SellerCompanyListModel
     */
    equipmentGroups?: Array<EquipmentGroupCountModel> | null;
}

/**
 * Check if a given object implements the SellerCompanyListModel interface.
 */
export function instanceOfSellerCompanyListModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SellerCompanyListModelFromJSON(json: any): SellerCompanyListModel {
    return SellerCompanyListModelFromJSONTyped(json, false);
}

export function SellerCompanyListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerCompanyListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'contactName': !exists(json, 'contactName') ? undefined : json['contactName'],
        'contactPhone': !exists(json, 'contactPhone') ? undefined : json['contactPhone'],
        'equipmentGroups': !exists(json, 'equipmentGroups') ? undefined : (json['equipmentGroups'] === null ? null : (json['equipmentGroups'] as Array<any>).map(EquipmentGroupCountModelFromJSON)),
    };
}

export function SellerCompanyListModelToJSON(value?: SellerCompanyListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'contactName': value.contactName,
        'contactPhone': value.contactPhone,
        'equipmentGroups': value.equipmentGroups === undefined ? undefined : (value.equipmentGroups === null ? null : (value.equipmentGroups as Array<any>).map(EquipmentGroupCountModelToJSON)),
    };
}


import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  ApplicationFormApi,
  ApplicationFormCreateModel,
  CurrencyApi,
  CurrencyModel,
  EquipmentApi,
  EquipmentGroupApi,
  EquipmentGroupListModel,
  FinancialCompanyApi,
  FinancialCompanyCreateModel,
  FinancialCompanyUpdateModel,
  LoginApi,
  LogoutApi,
  MaturityOptionApi,
  MaturityOptionModel,
  Middleware,
  OfferApi,
  OfferCalculatorRequestModel,
  RequestContext,
  ResponseContext,
  SearchModel,
  SellerCompanyApi,
  SellerCompanyCreateModel,
  SellerCompanySearchModel,
  SellerCompanyUpdateModel,
  UserLoginModel
} from 'src/service';
import { loadingAtom, messageAtom, unauthorizedResponseAtom, userAtom } from '../utils/atoms/global-atoms';

let allCurrencies: CurrencyModel[] | undefined = undefined;
let allMaturityOptions: MaturityOptionModel[] | undefined = undefined;
let equipments: EquipmentGroupListModel[] | undefined = undefined;

const baseLoginApi = new LoginApi();
const baseLogoutApi = new LogoutApi();
const baseCurrencyApi = new CurrencyApi();
const baseMaturityOptionApi = new MaturityOptionApi();
const baseFinancialCompanyApi = new FinancialCompanyApi();
const baseOfferApi = new OfferApi();
const baseEquipmentApi = new EquipmentApi();
const baseApplicationFormApi = new ApplicationFormApi();
const baseSellerCompaniesApi = new SellerCompanyApi();
const baseEquipmentGroupsApi = new EquipmentGroupApi();

const postConfig = (prelogin?: boolean, additionalHeaders?: Record<string, string>) => {
  const headers = {
    'Content-Type': 'application/json',
    ...additionalHeaders
  };

  const initObj = getModeAndCredentialsHeader(prelogin);
  initObj.headers = headers;
  return initObj;
};

const getConfig = (prelogin?: boolean) => {
  return getModeAndCredentialsHeader(prelogin);
};

const getModeAndCredentialsHeader = (prelogin?: boolean) => {
  const initObj: RequestInit = {
    mode: 'cors'
  };
  if (!prelogin) initObj.credentials = 'include';
  return initObj;
};

export type PaginationType = {
  current: undefined | number;
  pageSize: undefined | number;
};

export const useSecureService = () => {
  const [, setLoading] = useAtom(loadingAtom);
  const [, setUser] = useAtom(userAtom);
  const [, setErrorMessage] = useAtom(messageAtom);
  const [, setUnauthorizedFired] = useAtom(unauthorizedResponseAtom);
  const { t } = useTranslation();

  const handleSecureCatchErrors = async (err: any) => {
    try {
      const x = await err.json();
      setErrorMessage({ message: x.title, type: 'error' });
      return;
    } catch {}

    if (err?.status === 401) {
      setUser(undefined);
      setErrorMessage({ message: t('common.401'), type: 'error' });
      setUnauthorizedFired(err && err.url ? err.url : 'unauthorized');
    } else if (err?.status === 500) {
      setErrorMessage({
        message: t('common.genericError'),
        type: 'error'
      });
    } else {
      const msg = await err.json();
      if (typeof msg === 'string') {
        setErrorMessage({ message: msg.toString(), type: 'error' });
      } else if (msg.title !== undefined) {
        setErrorMessage({ message: msg.title, type: 'error' });
      } else {
        setErrorMessage({
          message: t('common.genericError'),
          type: 'error'
        });
      }
    }
  };

  //eslint-disable-next-line
  const handleParameterErrors = async (missingParams: string[]) => {
    setErrorMessage({ message: missingParams.toString(), type: 'error' });
  };

  const baseMiddleware: Middleware = {
    pre: async (params: RequestContext) => {
      console.log(':::REQUEST SENT:::');
      console.log(params);
      setLoading(true);
    },
    post: async (params: ResponseContext) => {
      console.log(params);
      console.log(':::RESPONSE RECEIVED:::');
      if (!params.response.ok) handleSecureCatchErrors(params.response);
      setLoading(false);
    }
  };

  const loginApi = baseLoginApi.withMiddleware(baseMiddleware);
  const currenciesApi = baseCurrencyApi.withMiddleware(baseMiddleware);
  const maturityOptionApi = baseMaturityOptionApi.withMiddleware(baseMiddleware);
  const logoutApi = baseLogoutApi.withMiddleware(baseMiddleware);
  const financialCompanyApi = baseFinancialCompanyApi.withMiddleware(baseMiddleware);
  const offerApi = baseOfferApi.withMiddleware(baseMiddleware);
  const equipmentApi = baseEquipmentApi.withMiddleware(baseMiddleware);
  const applicationFormApi = baseApplicationFormApi.withMiddleware(baseMiddleware);
  const sellerCompaniesApi = baseSellerCompaniesApi.withMiddleware(baseMiddleware);
  const equipmentGroupsApi = baseEquipmentGroupsApi.withMiddleware(baseMiddleware);

  const doLogin = async (model: UserLoginModel) => {
    try {
      const result = await loginApi.loginPost({ userLoginModel: model }, postConfig(false));
      setUser(result);
      return true;
    } catch (err: any) {
      return false;
    }
  };

  const doLogout = async () => {
    try {
      await logoutApi.logoutGet(getConfig());
      setUser(undefined);
      return true;
    } catch (err: any) {
      return false;
    }
  };

  const doGetCurrencies = async () => {
    if (allCurrencies) return allCurrencies;
    try {
      const result = await currenciesApi.currencyGet(getConfig(true));
      if (result.data) {
        allCurrencies = result.data;
        return result.data;
      } else return undefined;
    } catch (err: any) {
      return undefined;
    }
  };

  const doGetMaturityOptions = async () => {
    if (allMaturityOptions) return allMaturityOptions;
    try {
      const result = await maturityOptionApi.maturityOptionGet(getConfig());
      if (result.data) {
        allMaturityOptions = result.data;
        return result.data;
      } else return undefined;
    } catch {}
  };

  const doGetAllFinancialCompanies = async (search: SearchModel) => {
    try {
      const result = await financialCompanyApi.financialCompanyAllPost(
        {
          searchModel: search
        },
        postConfig()
      );
      if (result.data) return result.data;
      else return undefined;
    } catch (err: any) {
      return undefined;
    }
  };

  const doUpdateFinancialCompany = async (model: FinancialCompanyUpdateModel) => {
    try {
      await financialCompanyApi.financialCompanyUpdatePut(
        {
          financialCompanyUpdateModel: model
        },
        postConfig()
      );
      return true;
    } catch {
      return false;
    }
  };

  const doCreateFinancialCompany = async (model: FinancialCompanyCreateModel) => {
    try {
      await financialCompanyApi.financialCompanyCreatePost({ financialCompanyCreateModel: model }, postConfig());
      return true;
    } catch {
      return false;
    }
  };

  const doOffer = async (model: OfferCalculatorRequestModel) => {
    try {
      const result = await offerApi.offerPost(
        {
          offerCalculatorRequestModel: model
        },
        postConfig()
      );
      if (result.data) return result.data;
      else return undefined;
    } catch {
      return undefined;
    }
  };

  const doGetAllEquipments = async () => {
    if (equipments) return equipments;
    try {
      // non-secure
      const result = await equipmentGroupsApi.equipmentGroupGuestAllWithEquipmentsGet(getConfig(true));
      if (result.data) {
        equipments = result.data;
        return result.data;
      }
      return undefined;
    } catch {
      return undefined;
    }
  };

  const doGetCompaniesOfEquipment = async (equipmentId: number) => {
    try {
      const result = await equipmentApi.equipmentEquipmentIdSellersGet(
        {
          equipmentId: equipmentId
        },
        getConfig(true)
      );
      return result.data ? result.data : undefined;
    } catch {
      return undefined;
    }
  };
  const doPostApplicationForm = async (model: ApplicationFormCreateModel) => {
    try {
      await applicationFormApi.applicationFormApplyPost(
        {
          applicationFormCreateModel: model
        },
        postConfig(true)
      );
      return true;
    } catch {
      return false;
    }
  };

  const doGetSellerCompanies = async (model: SellerCompanySearchModel) => {
    try {
      const result = await sellerCompaniesApi.sellerCompanyAllPost(
        {
          sellerCompanySearchModel: model
        },
        postConfig()
      );
      if (result.data) return result.data;
      return undefined;
    } catch {
      return undefined;
    }
  };
  const doGetSellerCompanyDetail = async (id: number) => {
    try {
      const result = await sellerCompaniesApi.sellerCompanyDetailSellerCompanyIdGet(
        {
          sellerCompanyId: id
        },
        getConfig()
      );
      if (result.data) return result.data;
      return undefined;
    } catch {
      return undefined;
    }
  };

  const doDeleteSellerCompany = async (id: number) => {
    try {
      await sellerCompaniesApi.sellerCompanySellerCompanyIdDelete(
        {
          sellerCompanyId: id
        },
        getConfig()
      );
    } catch {
      return undefined;
    }
  };

  const doCreateSellerCompany = async (model: SellerCompanyCreateModel) => {
    try {
      await sellerCompaniesApi.sellerCompanyCreatePost({ sellerCompanyCreateModel: model }, postConfig());
    } catch {}
  };

  const doUpdateSellerCompany = async (model: SellerCompanyUpdateModel) => {
    try {
      await sellerCompaniesApi.sellerCompanyUpdatePut({ sellerCompanyUpdateModel: model }, postConfig());
    } catch {}
  };

  const doGetAllApplications = async (model: SearchModel) => {
    try {
      const result = await applicationFormApi.applicationFormAllPost(
        {
          searchModel: model
        },
        postConfig()
      );
      if (result.data) return result.data;
      return undefined;
    } catch {
      return undefined;
    }
  };

  const doGetAllFinancialCompaniesByConversion = async (model: SearchModel) => {
    try {
      const result = await financialCompanyApi.financialCompanyAllByConversionsPost({ searchModel: model }, postConfig());
      if (result.data) return result.data;
      return undefined;
    } catch {
      return undefined;
    }
  };

  const doDeactivateFinancialCompany = async (companyId: number) => {
    try {
      await financialCompanyApi.financialCompanyToggleStatusCompanyIdPut(
        {
          companyId: companyId
        },
        postConfig()
      );
      return true;
    } catch {
      return false;
    }
  };

  return {
    doLogin,
    doLogout,
    doGetCurrencies,
    doGetMaturityOptions,
    doGetAllFinancialCompanies,
    doUpdateFinancialCompany,
    doCreateFinancialCompany,
    doOffer,
    doGetAllEquipments,
    doGetCompaniesOfEquipment,
    doPostApplicationForm,
    doGetSellerCompanies,
    doGetSellerCompanyDetail,
    doDeleteSellerCompany,
    doCreateSellerCompany,
    doUpdateSellerCompany,
    doGetAllApplications,
    doGetAllFinancialCompaniesByConversion,
    doDeactivateFinancialCompany
  };
};

import React from 'react';
import { useStyle } from 'src/hooks/useStyles';
import { useTheme } from 'src/hooks/useTheme';
import { margin } from 'src/styles/layoutStyles';
import { ButtonProps } from '.';
import { Icon } from '../icon';
import { Typography } from '../typography';
import { baseStyles } from './button.styles';
import './button.css';

export const Button = (props: ButtonProps) => {
  const { onPress, label = '', style, rightIcon, type = 'button', disabled, isLoading, fontFamily, children } = props;
  const styles = useStyle(baseStyles);
  const theme = useTheme();

  return (
    <button
      type={type}
      style={{
        ...styles.container,
        ...style
      }}
      onClick={onPress}
      disabled={disabled || isLoading}
    >
      {isLoading && <i className="fa fa-refresh fa-spin" style={{ ...margin(2, 'right'), color: theme.colors.color1 }} />}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {children}
        <Typography size={16} fontFamily={fontFamily ? fontFamily : 'Medium'} color="color1">
          {label}
        </Typography>
        {rightIcon && <Icon icon={rightIcon} size={20} color={theme.colors.color1} style={margin(2, 'left')} />}
      </div>
    </button>
  );
};

import { ViewStyleProps } from 'src/components/view';
import { MAX_CONTAINER_WIDTH } from 'src/utils/constants';
import { toUpperCase } from 'src/utils/string';

export const layoutStyles: Record<any, ViewStyleProps> = {
  fullFlex: {
    display: 'flex',
    flex: 1
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  selfCenter: {
    alignSelf: 'center'
  },
  maxWidth: {
    maxWidth: MAX_CONTAINER_WIDTH
  }
};

type SpacingTypes = {
  type: 'margin' | 'padding';
  x: number;
  align: 'top' | 'left' | 'bottom' | 'right' | 'all' | 'horizontal' | 'vertical';
};

export const bgColor = (color: string) => {
  return { backgroundColor: color };
};

export const boxShadow = (color: string) => {
  return {
    boxShadow: `0px 8px 20px ${color}`
  };
};

export const margin = (x: SpacingTypes['x'], align: SpacingTypes['align']) => {
  return spacing({
    type: 'margin',
    x,
    align
  });
};

export const padding = (x: SpacingTypes['x'], align: SpacingTypes['align']) => {
  return spacing({
    type: 'padding',
    x,
    align
  });
};

const spacing = (props: SpacingTypes) => {
  const { type, x, align } = props;
  const key = type;
  const value = 4 * x;
  const style: any = {};

  switch (align) {
    case 'all':
      style[key] = value;
      break;
    case 'horizontal':
      style[key + 'Left'] = value;
      style[key + 'Right'] = value;
      break;
    case 'vertical':
      style[key + 'Top'] = value;
      style[key + 'Bottom'] = value;
      break;
    case 'top':
    case 'left':
    case 'bottom':
    case 'right':
      style[key + toUpperCase(align)] = value;
      break;
    default:
      break;
  }

  return style;
};

import React from 'react';
import { View } from 'src/components/view';
import { useMediaQueryVariables } from 'src/hooks/useMediaQuery';
import { layoutStyles, padding } from 'src/styles/layoutStyles';
import { ContentContainerProps } from '.';

export const ContentContainer = (props: ContentContainerProps) => {
  const { style, ...remainingProps } = props;
  const { isTabletOrMobile } = useMediaQueryVariables();
  return (
    <View
      style={[layoutStyles.fullFlex, layoutStyles.column, layoutStyles.justifyCenter, padding(isTabletOrMobile ? 5 : 34, 'horizontal'), style]}
      {...remainingProps}
    />
  );
};

import { activeBaseUrl } from 'src/utils/constants';

export const headerLabelKeys = [
  {
    label: 'menu.homepage',
    to: activeBaseUrl + '/'
  },
  {
    label: 'menu.calculation-tool',
    to: activeBaseUrl + '/#calculation-tool'
  },
  {
    label: 'menu.what-you-wonder',
    to: activeBaseUrl + '/faq'
  },
  {
    label: 'menu.why-gglease',
    to: activeBaseUrl + '/#why-gglease'
  },
  {
    label: 'menu.our-process',
    to: activeBaseUrl + '/#our-process'
  },
  {
    label: 'menu.blog',
    to: activeBaseUrl + '/blog'
  }
];

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'src/components/typography';
import { WhiteCard } from 'src/components/whiteCard';
import { loadingAtom } from 'src/utils/atoms/global-atoms';
import { useAtom } from 'jotai';
import { AdminContainer } from 'src/components/adminContainer';
import { PaginationType, useSecureService } from 'src/hooks/service-hooks';
import { EditOutlined, PlusCircleFilled, DeleteOutlined } from '@ant-design/icons';
import { EquipmentGroupCountModel, SellerCompanyDetailModel, SellerCompanyListModel, SellerCompanySearchFilterModel } from 'src/service';
import { Form, Space, TablePaginationConfig, Tooltip } from 'antd';
import DataTable from 'src/components/dataTable/data-table';
import { Button } from 'src/components/button';
import { AddSellerCompany } from 'src/components/modals/addSellerCompany';
import { ColumnsType } from 'antd/es/table';
import { useForm } from 'antd/es/form/Form';
import _ from 'lodash';
import { CustomInput } from 'src/components/input';
import { Data } from 'react-csv/components/CommonPropTypes';
import { CSVLink } from 'react-csv';
export const SellerCompanyManagementView = () => {
  //const { isTabletOrMobile } = useMediaQueryVariables();
  const { t } = useTranslation();
  const [loading] = useAtom(loadingAtom);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [sellerCompanies, setSellerCompanies] = useState<SellerCompanyListModel[] | undefined>(undefined);
  const [selectedModel, setSelectedModel] = useState<SellerCompanyDetailModel | undefined>(undefined);
  const [filterForm] = useForm<SellerCompanySearchFilterModel>();

  const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [allRows, setAllRows] = useState<Data>([]);
  const initialColumnsArr: ColumnsType<SellerCompanyListModel> = [
    {
      title: t('sellerCompanyManagement.table.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('sellerCompanyManagement.table.email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('sellerCompanyManagement.table.contactName'),
      dataIndex: 'contactName',
      key: 'contactName'
    },
    {
      title: t('sellerCompanyManagement.table.contactPhone'),
      dataIndex: 'contactPhone',
      key: 'contactPhone'
    },
    {
      title: t('sellerCompanyManagement.table.sellerCompanyEquipments'),
      dataIndex: 'equipmentGroups',
      key: 'equipmentGroups',
      render: (values: EquipmentGroupCountModel[], record: SellerCompanyListModel) => {
        return (
          <>
            {}
            {values.map((item) => {
              return (
                <>
                  {`${item.name} (${item.count})`}
                  <br />
                </>
              );
            })}
          </>
        );
      }
    },
    {
      title: '',
      key: 'action',
      render: (text: string, record: SellerCompanyListModel) => (
        <Space size="middle">
          <Tooltip title={t('common.edit')}>
            <EditOutlined
              onClick={async () => {
                await getSellerCompanyDetail(record.id!);
                setAddModalVisible(true);
              }}
              rev={undefined}
            />
          </Tooltip>
          <Tooltip title={t('common.delete')}>
            <DeleteOutlined
              onClick={async () => {
                await doDeleteSellerCompany(record.id!);
              }}
              rev={undefined}
            />
          </Tooltip>
        </Space>
      )
    }
  ];
  const [callAgain, setCallAgain] = useState(0);
  const [itemCount, setItemCount] = useState<number | undefined>(1);
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    pageSize: 10
  });
  const { doGetSellerCompanies, doDeleteSellerCompany, doGetSellerCompanyDetail } = useSecureService();

  const getSellerCompanyDetail = async (id: number) => {
    const result = await doGetSellerCompanyDetail(id);
    setSelectedModel(result);
  };

  useEffect(() => {
    const getAllSellerCompanies = async () => {
      const filterFormValues: SellerCompanySearchFilterModel = filterForm.getFieldsValue();

      const result = await doGetSellerCompanies({
        pageSize: pagination.pageSize,
        pageNo: pagination.current,
        filter: {
          name: _.isEmpty(filterFormValues.name) ? null : filterFormValues.name
        }
      });
      setItemCount(result?.itemCount);
      setSellerCompanies(result?.data || undefined);
    };

    getAllSellerCompanies();
    //eslint-disable-next-line
  }, [callAgain]);

  const handleChange = (tablePagination: TablePaginationConfig) => {
    setPagination((curr) => {
      return { ...curr, current: tablePagination.current };
    });
    setCallAgain((val) => val + 1);
  };

  const handleSearch = () => {
    setPagination({
      current: 1,
      pageSize: 10
    });
    setCallAgain((val) => val + 1);
  };

  let headers = [
    { label: 'Id', key: 'id' },
    { label: 'Firma Adı', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Kontakt Şahıs', key: 'contactName' },
    { label: 'Kontakt Tel', key: 'contactPhone' },
    { label: 'Ekipman', key: 'equipment' }
  ];

  const exportClick = async () => {
    if (!itemCount || itemCount <= 0) {
      return;
    }
    const result = await doGetSellerCompanies({ pageNo: 1, pageSize: itemCount });
    if (result?.data?.length !== undefined && result?.data?.length > 0) {
      let manipulatedData: Data = [];
      result.data.forEach((item: SellerCompanyListModel) => {
        const newItem: any = item;
        newItem.id = item.id;
        newItem.name = item.name;
        newItem.email = item.email;
        newItem.contactName = item.contactName;
        newItem.contactPhone = item.contactPhone;
        item.equipmentGroups?.forEach((eq) => {
          const equipment = `${eq.name} (${eq.count})`;
          newItem.equipment = (newItem.equipment ?? '') + '\n' + equipment;
        });
        manipulatedData.push(newItem);
      });
      setAllRows(manipulatedData);
    }
  };

  useEffect(() => {
    if (!allRows || allRows.length === 0) return;
    csvRef.current?.link.click();
  }, [allRows]);

  return (
    <AdminContainer activeNode={'/seller-management'}>
      <WhiteCard>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography size={20} fontFamily="SemiBold">
            {t('sellerCompanyManagement.page.title')}
          </Typography>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}>
            <Form
              key="filter"
              name="filter"
              form={filterForm}
              onFinish={() => {
                handleSearch();
              }}
              style={{ maxWidth: 'none' }}
              layout="horizontal"
            >
              <Space align="start">
                <Form.Item name="name">
                  <CustomInput
                    style={{
                      height: '52px'
                    }}
                    allowClear
                    placeholder={t('sellerCompanyManagement.addPopup.companyNamePlaceholder')}
                  />
                </Form.Item>
                <Form.Item>
                  <Button isLoading={loading} type="submit" style={{ width: 'fit-content' }} label={t('common.search')} />
                </Form.Item>
              </Space>
            </Form>
            <Button
              label={t('sellerCompanyManagement.page.ctaLabel')}
              onPress={() => {
                setSelectedModel(undefined);
                setAddModalVisible(true);
              }}
            >
              <PlusCircleFilled style={{ color: '#FFFFFF', marginRight: '8px', fontSize: '18px' }} rev={undefined} />
            </Button>
            <Button onPress={exportClick} label={t('common.export-as-csv')} />
          </div>
        </div>
        <CSVLink filename="satici_firmalar.csv" ref={csvRef} data={allRows} headers={headers} separator={';'} />
        <DataTable
          onChange={handleChange}
          columns={initialColumnsArr}
          dataSource={sellerCompanies}
          loading={loading}
          pagination={{ ...pagination, position: ['bottomCenter'], total: itemCount, showSizeChanger: false }}
        />
      </WhiteCard>
      <AddSellerCompany
        model={selectedModel}
        show={addModalVisible}
        onCloseClick={(refresh) => {
          if (refresh) {
            setCallAgain((oldVal) => oldVal + 1);
          }
          setAddModalVisible(false);
        }}
      ></AddSellerCompany>
    </AdminContainer>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'src/components/container';
//import { useMediaQueryVariables } from "src/hooks/useMediaQuery";
import { Typography } from 'src/components/typography';
import { WhiteCard } from 'src/components/whiteCard';
import { Button } from 'src/components/button';
import { Form } from 'antd';
import { loadingAtom } from 'src/utils/atoms/global-atoms';
import { useAtom } from 'jotai';
import { useSecureService } from 'src/hooks/service-hooks';
import { UserLoginModel } from 'src/service';
import { useNavigate } from 'react-router-dom';
import { CustomInput } from 'src/components/input';

export const LoginView = () => {
  //const { isTabletOrMobile } = useMediaQueryVariables();
  const { t } = useTranslation();
  const [loading] = useAtom(loadingAtom);
  const navigate = useNavigate();
  const { doLogin } = useSecureService();

  const formFinishHandler = async (formParams: UserLoginModel) => {
    const result = await doLogin(formParams);
    if (result) {
      navigate('/dashboard');
    }
  };

  return (
    <Container hideFooter>
      <div
        style={{
          width: '100%',
          height: '100%',
          background: 'linear-gradient(179.52deg, #526EAF -281.78%, #1A2845 94.79%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <WhiteCard>
          <Typography style={{ marginTop: '24px' }} fontFamily="Bold" size={40} isCenter>
            {t('login.form.title')}
          </Typography>
          <Form onFinish={formFinishHandler}>
            <Form.Item name="email" rules={[{ type: 'email', required: true, message: t('login.form.usernameValidation') || '' }]}>
              <CustomInput placeholder={t('login.form.usernamePlaceholder') ?? ''} />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: t('login.form.passwordValidation') || '', min: 4, max: 32 }]}>
              <CustomInput type="password" placeholder={t('login.form.passwordPlaceholder') ?? ''} />
            </Form.Item>
            <Form.Item>
              <Button isLoading={loading} style={{ width: '100%' }} type="submit" label={t('login.form.submit')}></Button>
            </Form.Item>
          </Form>
        </WhiteCard>
      </div>
    </Container>
  );
};

export const tr = {
  'menu.homepage': 'Ana Sayfa',
  'menu.calculation-tool': 'Hesaplama Aracı',
  'menu.what-you-wonder': 'Merak Ettikleriniz',
  'menu.why-gglease': 'Neden GGlease ?',
  'menu.our-process': 'Sürecimiz',
  'menu.blog': 'Blog',
  'menu.contact-us': 'Bize Ulaşın',
  'menu.about-us': 'Hakkımızda',
  'menu.kvkk-application': 'KVKK Başvuru Formu',
  'menu.kvkk-policy': 'KVKK Politikası',
  'menu.cookie-policy': 'Çerez Politikası',
  'modal.kvkk-application': 'KVKK Başvuru Formu',
  'modal.kvkk-policy': 'KVKK Politikası',
  'modal.cookie-policy': 'Çerez Politikası',
  'contact.phone': '90 533 620 40 23',
  'contact.email': 'info@gglease.com',
  'calculation.title': "Ödeme Planınızı <text class='greenText'>Kolayca</text> Hesaplayın",
  'calculation.inputs.equipmentValuePlaceholder': 'Örn: 100.000',
  'calculation.inputs.equipmentValueLabel': 'Mal Bedeli',
  'calculation.inputs.amountValidation': 'Bir tutar giriniz',
  'calculation.inputs.maxAmountValidation': 'Mal Bedelinden büyük olamaz',
  'calculation.inputs.dropdownValidation': 'Bir seçenek seçiniz',
  'calculation.inputs.advancePaymentLabel': 'Peşinat',
  'calculation.inputs.advancePaymentPlaceholder': 'Örn:1.000',
  'calculation.inputs.currencyIdLabel': 'Para Birimi',
  'calculation.inputs.currencyIdPlaceholder': 'Seçiniz',
  'calculation.inputs.maturityOptionIdLabel': 'Vade',
  'calculation.inputs.maturityOptionIdPlaceholder': 'Seçiniz',
  'calculation.inputs.maturityOptionIdResult': 'Vade',
  'calculation.offerResult.equipmentValueLabel': 'Mal Bedeli',
  'calculation.offerResult.totalPayment': 'Toplam Ödeme: ',
  'calculation.offerResult.monthlyPayment': 'Aylık Ödeme',
  'calculation.offerResult.applyNow': 'Hemen Başvur',
  'calculation.disclaimer': 'Teklifler gösterge niteliğindedir. KDV hariçtir.',
  'how-does-it-work.title': "<text class='greenText'>Nasıl</text> Çalışır?",
  'how-does-it-work.step-1': 'Başvuru Formunu Doldurun',
  'how-does-it-work.step-2': 'Şirketlerden sizin için çalışılmış teklifleri derleyelim',
  'how-does-it-work.step-3': 'Derlediğimiz tekliflerden sizin için en uygun olanını seçin',
  'how-does-it-work.step-4': 'İşinizi ve hayallerinizi büyütün',
  'contact.firmName': 'GG Finansal Danışmanlık ve Teknoloji A.Ş.',
  'contact.address': 'Fatih Sultan Mehmet Mah. Poligon Cad. Buyaka 2 Blok 3 No: 8C/1 Ümraniye/İstanbul Türkiye',
  'footer.info': '© GGlease 2023',
  'why-gg-lease.title': "<text class='greenText'>Neden</text> GGlease",
  'why-gg-lease.description':
    'Leasing’i deneyenler bilir, alternatif çözümler sınırlıdır, süreçler uzundur ve karmaşık görünür. GGlease leasing kullanıcılarına yenilikçi ve kolay çözümler sunarken leasing bilmeyenleri de yepyeni bir dünyaya davet ediyor.',
  'why-gg-lease.quick-access-to-financing': 'Hızlı Leasing Teklifi',
  'why-gg-lease.multiple-alternative': 'Çoklu Leasing Teklifi',
  'why-gg-lease.easy-management-of-the-process': 'Farklı Satıcı Olasılığı',
  'why-gg-lease.best-term': 'Farklı Döviz',
  'why-gg-lease.different-currency': 'Farklı Vade',
  'why-gg-lease.investment-consulting': 'Finansmana Erişim Yolu',
  'why-gg-lease.current-stock-dataset': 'Leasing Rehberliği',
  'why-gg-lease.section.equity': 'Özkaynak',
  'why-gg-lease.section.vendor-credit': 'Satıcı Kredisi',
  'why-gg-lease.section.bank-credit': 'Banka Kredisi',
  'why-gg-lease.section.leasing-company': 'Leasing Şirketi',
  'product-card.badge.new': 'YENİ',
  'product-card.badge.secondhand': 'İKİNCİ EL',
  'product-card.bottom-info': '*Fiyatlar ve teklifler indikatif olup bağlayıcılığı yoktur.',
  'for-all-your-needs.title': "<text class='greenText'>Bütün</text> Ekipman İhtiyaçlarınız İçin",
  'for-all-your-needs.description': 'İhtiyacınız olan bütün ekipmanlar burada. Aradığınız ekipmanı listede göremiyorsanız bizimle iletişime geçin',
  'what-we-do.title': "GGlease <text class='greenText'>Ne Yapar?</text>",
  'what-we-do.description':
    'Yatırım yapmak istediğiniz ekipman sıfır / ikinci el olsun, en hızlı ve rekabetçi ödeme tablolarını size sunar. Almanız gereken hizmetleri size ulaştırır. Leasing süreçlerinin her noktasında size danışmanlık verir. İşinizi büyütür.',
  'slide-banner.for-equipment-investment.title': 'Ekipman yatırımlarınız için',
  'slide-banner.for-equipment-investment.subTitle': 'İşini büyütmek, cirosunu yükseltmek, kârlılığını artırmak isteyenlerin doğru adresi GGlease',
  'slide-banner.for-equipment-investment.description':
    'Sıfır ya da ikinci el olsun farketmez, her türlü ekipman yatırımlarınız için GGlease’de size uygun bir çözümümüz var. Son kararınızı gelin birlikte verelim.',
  'slide-banner.for-easy-financing.title': 'Kolay finansman için',
  'slide-banner.for-easy-financing.subTitle':
    'Nakit akışınıza uygun, farklı döviz birimleri ve farklı vadeleri içeren ödeme planı isteyenlerin doğru adresi GGlease',
  'slide-banner.for-easy-financing.description':
    'Nakit akışınıza göre, borcunuzu taksitler halinde ödeyebilirsiniz. Hatta ödemesiz dönem imkanınız bile var. Mali durumunuzu zorlamadan borcunuzu uzun vadeye yayabilirsiniz. Taksitleriniz bittiğinde de ekipmanınıza sahip olursunuz.',
  'slide-banner.services-for-equipments.title': 'Ekipmanlarınız için hizmetler',
  'slide-banner.services-for-equipments.subTitle': 'Ekipmanlarınız için her türlü hizmeti tek bir noktadan alabileceğiniz doğru adres GGlease',
  'slide-banner.services-for-equipments.description':
    'Ekipmanın ekspertiz raporu, sigorta poliçesi, her türlü tamir ve bakım hizmetleri için farklı kanallardan size uygun çözümleri sağlıyoruz.',
  'contact.form.title': 'Ekipman Yatırımlarınız İçin Yeni Nesil Leasing',
  'contact.form.description': 'Hemen Formu Doldurun, Kolay Leasing İle Tanışın!',
  'contact.form.submit-success': 'Form başarıyla gönderildi.',
  'contact.form.submit-error': 'Form gönderilirken bir hata oluştu.',
  'register-card.leasing-type.select-placeholder': 'İhtiyacınız Olan Ekipmanı Seçiniz',
  'register-card.company.select-placeholder': 'Satıcı Firma Seçiniz',
  'register-card.placeholder-name': 'Ad Soyad',
  'register-card.placeholder-email': 'E - Posta Adresi',
  'register-card.placeholder-phone': 'Telefon Numarası',
  'register-card.placeholder-vkn': 'Vergi Kimlik Numarası',
  'register-card.placeholder-otherCompany': 'Firma Adı',
  'register-card.placeholder-description': 'Açıklama',
  'register-card.send-form': 'Formu Gönder',
  'register-card.leasing-type.construction': 'İŞ VE İNŞAAT MAKİNELERİ',
  'register-card.leasing-type.metalworking': 'METAL İŞLEME MAKİNELERİ',
  'register-card.leasing-type.plastic': 'PLASTİK İŞLEME MAKİNELERİ',
  'register-card.leasing-type.textile': 'TEKSTİL MAKİNELERİ',
  'register-card.leasing-type.agricultural': 'TARIM VE HAYVANCILIK MAKİNELERİ',
  'register-card.leasing-type.health-sector': 'SAĞLIK SEKTÖRÜ VE ESTETİK EKİPMANLARI',
  'register-card.leasing-type.electronic-and-optical': 'ELEKTRONİK VE OPTİK CİHAZLAR',
  'register-card.leasing-type.information-processing': 'BİLGİ İŞLEM VE BÜRO SİSTEMLERİ',
  'register-card.leasing-type.renewable-source-electric-energy': 'YENİLENEBİLİR KAYNAKLI ELEKTRİK ENERJİSİ ÜRETİM EKİPMANLARI',
  'register-card.leasing-type.other-machines': 'DİĞER MAKİNE VE EKİPMANLAR',
  'register-card.leasing-type.second-hand': '2. EL MAKİNE',
  'register-card.leasing-type.other': 'DİĞER',
  'register-card.register-link': 'KVKK Politikası',
  'register-card.register-text': "'nı okudum, anladım, onaylıyorum.",
  'common.yup.mixed.default': 'Lütfen doğru bir değer giriniz',
  'common.yup.mixed.notType': 'Lütfen bu alanı doldurunuz.',
  'common.yup.mixed.required': 'Lütfen bu alanı doldurunuz.',
  'common.yup.string.email': 'Lütfen geçerli bir e-posta girin',
  'about-us.title': 'Hakkımızda',
  'faq.title': "Tüm <text class='greenText'>Merak</text> Ettikleriniz",
  'faq.description': 'Sana Nasıl Yardımcı Olabiliriz?',
  'faq.show-all-questions': 'Tamamını Görmek İçin Tıklayınız',
  'faq.question-1.title': 'GGlease nedir?',
  'faq.question-1.description': 'GGlease ekipman yatırımı yapmak isteyenler için tüm tarafların bir arada olduğu bir çözüm platformudur.',
  'faq.question-2.title': 'GGlease kimdir?',
  'faq.question-2.description':
    'Biz bankacılık ve finansal hizmetler alanında toplam 50 seneyi aşan tecrübemizle GGlease’i kurduk. Amacımız; ekonomilerin omurgası olan KOBİ’lerin ekipman yatırımlarında onları en doğru çözüme yönlendirmek; tek platformda her türlü alternatife kolayca ulaşabilmelerini sağlamak. Böylece sürdürülebilir ekonomik ve sosyal kalkınmaya katkıda bulunmayı hedefliyoruz.',
  'faq.question-3.title': 'GGlease’in leasing şirketlerinden farkı nedir?',
  'faq.question-3.description':
    'GGlease bir leasing şirketi değildir. Ancak ekipman yatırımlarınızı leasing ile yapmak istiyorsanız farklı marka ve modellerde, sıfır ya da ikinci el ekipmanları ve tercih ettiğiniz ekipmanın taksit tablosunu GGlease’de arayıp bulabilirsiniz.',
  'faq.question-4.title': 'GGlease’den nasıl yararlanabilirim?',
  'faq.question-4.description': 'GGlease’in ilk sayfasındaki başvuru formunu doldurmanız yeterdir.',
  'blog.title': 'Blog',
  'login.form.title': 'Giriş Yap',
  'login.form.usernamePlaceholder': 'e-mail',
  'login.form.passwordPlaceholder': 'şifre',
  'login.form.submit': 'Giriş Yap',
  'login.form.usernameValidation': 'Lütfen emailinizi giriniz.',
  'login.form.passwordValidation': 'Lütfen şifrenizi giriniz.',
  'menu.exit': 'Çıkış Yap',
  'menu.dashboard': 'Başvurular',
  'menu.reports': 'Raporlar',
  'menu.seller-management': 'Satıcı Firma Yönetimi',
  'menu.financial-management': 'Finansal Kurum ve Hesaplama Aracı Yönetimi',
  'financialManagement.table.id': 'Firma ID',
  'financialManagement.table.name': 'Firma Adı',
  'financialManagement.table.updatedOn': 'Düzenlenme Tarihi',
  'financialManagement.table.twelveMonths': '12 Ay',
  'financialManagement.table.twFourMonths': '24 Ay',
  'financialManagement.table.thSixMonths': '36 Ay',
  'financialManagement.table.thSixPlusMonths': '48 Ay',
  'financialManagement.page.title': 'Finansal Kurum ve Hesaplama Aracı Yönetimi',
  'financialManagement.page.ctaLabel': 'Finansal Kurum Ekle',
  'financialManagement.addPopup.title': 'Firma Ekle',
  'financialManagement.addPopup.companyNamePlaceholder': 'Finansal Kurum Adı',
  'financialManagement.addPopup.twelveMonths': '12 Ay',
  'financialManagement.addPopup.twFourMonths': '24 Ay',
  'financialManagement.addPopup.thSixMonths': '36 Ay',
  'financialManagement.addPopup.thSixPlusMonths': '48 Ay',
  'financialManagement.addPopup.companyValidation': 'Bir kurum adı giriniz',
  'financialManagement.addPopup.rateValidation': 'Geçerli bir oran giriniz',
  'sellerCompanyManagement.page.title': 'Satıcı Firma Yönetimi',
  'sellerCompanyManagement.table.name': 'Firma Adı',
  'sellerCompanyManagement.table.email': 'E-posta',
  'sellerCompanyManagement.table.contactName': 'Kontakt Şahıs',
  'sellerCompanyManagement.table.contactPhone': 'Kontakt Tel',
  'sellerCompanyManagement.table.sellerCompanyEquipments': 'Kategorileri',
  'sellerCompanyManagement.page.ctaLabel': 'Firma Ekle',
  'sellerCompanyManagement.addPopup.title': 'Firma Ekle',
  'sellerCompanyManagement.addPopup.companyValidation': 'Bir firma adı giriniz',
  'sellerCompanyManagement.addPopup.contactNameValidation': 'Bir kontakt adı giriniz',
  'sellerCompanyManagement.addPopup.contactPhoneValidation': 'Bir telefon numarası giriniz',
  'sellerCompanyManagement.addPopup.emailValidation': 'Bir eposta adresi giriniz',
  'sellerCompanyManagement.addPopup.equipmentValidation': 'Bir kategori giriniz',
  'sellerCompanyManagement.addPopup.companyNamePlaceholder': 'Firma adı',
  'sellerCompanyManagement.addPopup.contactNamePlaceholder': 'Kontakt adı',
  'sellerCompanyManagement.addPopup.contactPhonePlaceholder': 'Telefon numarası',
  'sellerCompanyManagement.addPopup.emailPlaceholder': 'Eposta adresi',
  'sellerCompanyManagement.addPopup.equipmentPlaceholder': 'Kategoriler',
  'dashboard.page.title': 'Başvurular',
  'dashboard.table.state-date': 'Hesaplama/Tarih',
  'dashboard.table.name-id': 'Ad Soyad/ID',
  'dashboard.table.equipment': 'Ekipman',
  'dashboard.table.equipmentMainCategory': 'E. Ana Kategori',
  'dashboard.table.equipmentSubCategory': 'E. Alt Kategori',
  'dashboard.table.phone': 'Telefon',
  'dashboard.table.company': 'Başvuran Firma',
  'dashboard.table.email': 'E-Posta',
  'dashboard.table.sellerCompany': 'Satıcı Firma',
  'dashboard.table.vkn': 'VKN',
  'dashboard.table.description': 'Açıklama',
  'dashboard.table.sellerEmpty': 'Boş bırakıldı',
  'dashboard.table.offer': 'Seçilen teklif',
  'dashboard.modal.equipmentValue': 'Mal Bedeli',
  'dashboard.modal.payment': 'Peşinat',
  'dashboard.modal.maturityOption': 'Vade',
  'dashboard.modal.offersTitle': 'Sunulan Teklifler',
  'dashboard.modal.totalPayment': 'Toplam Ödeme: ',
  'dashboard.modal.monthlyPayment': 'Aylık Ödeme',
  'reportsView.page.title': 'Raporlar',
  'reportsView.table.name': 'Finans Kurumu',
  'reportsView.table.TotalView': 'Yapılan Yönlendirme Sayısı',
  'reportsView.table.conversions': 'Dönüşümler',
  'common.view': 'Görüntüle',
  'common.edit': 'Düzenle',
  'common.delete': 'Sil',
  'common.publish': 'Yayınla',
  'common.export-as-csv': 'Export',
  'common.calculate': 'Hesapla',
  'common.401': 'Oturumunuz sonlanmıştır, lütfen tekrar giriş yapınız.',
  'common.genericError': 'Beklenmeyen bir hata oluştu.',
  'common.search': 'Ara'
};

import React, { useEffect, useState } from 'react';
import { AddSellerCompanyProps } from '.';
import { useSecureService } from 'src/hooks/service-hooks';
import { EquipmentGroupListModel } from 'src/service';
import LightboxContainer from '../lightBoxContainer/lightBoxContainer';
import { Col, Form, Row, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button';
import { allowOnlyDecimals } from 'src/utils/string';
import { loadingAtom } from 'src/utils/atoms/global-atoms';
import { useAtom } from 'jotai';
import { CustomInput } from 'src/components/input';
import { CustomTreeSelect } from 'src/components/customTreeSelect';
export const AddSellerCompany = (props: AddSellerCompanyProps) => {
  const { model, show, onCloseClick } = props;
  const [loading] = useAtom(loadingAtom);
  const [equipments, setEquipments] = useState<EquipmentGroupListModel[] | undefined>(undefined);
  const { doGetAllEquipments, doCreateSellerCompany, doUpdateSellerCompany } = useSecureService();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const generateTreeData = (equipments: any[] | undefined) => {
    return equipments?.map((group: { name: any; id: any; equipments: any[] }) => ({
      title: group.name,
      value: `group-${group.id}`,
      key: `group-${group.id}`,
      children: group.equipments?.map((equipment: { name: any; id: any }) => ({
        title: equipment.name,
        value: equipment.id,
        key: equipment.id
      }))
    }));
  };
  const treeData = generateTreeData(equipments);
  const tProps = {
    treeData,
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_CHILD,
    placeholder: t('sellerCompanyManagement.addPopup.equipmentPlaceholder'),
    style: {
      width: '100%'
    }
  };

  useEffect(() => {
    const getParams = async () => {
      const result = await doGetAllEquipments();
      setEquipments(result);
    };
    getParams();
    //eslint-disable-next-line
  }, []);

  const formFinishHandler = async (formData: any) => {
    if (model) {
      await doUpdateSellerCompany({
        id: model.id,
        ...formData
      });
    } else {
      await doCreateSellerCompany({
        ...formData
      });
    }
    props.onCloseClick(true);
  };

  useEffect(() => {
    if (!show) return;
    form.resetFields();
    if (!model) return;
    form.setFieldsValue(model);

    //eslint-disable-next-line
  }, [model, show]);

  const onValuesChange = (changedValues: any, values: any) => {
    for (const key in changedValues) {
      if (Object.prototype.hasOwnProperty.call(changedValues, key)) {
        if (key.includes('-')) {
          const newValue = allowOnlyDecimals(changedValues[key]);
          form.setFieldValue(key, newValue);
        }
      }
    }
  };

  return (
    <LightboxContainer title={t('sellerCompanyManagement.addPopup.title')} show={show} onCloseClick={onCloseClick}>
      <Form form={form} onValuesChange={onValuesChange} onFinish={formFinishHandler}>
        <Row gutter={8}>
          <Col xs={24}>
            <Form.Item
              name="name"
              style={{ marginTop: '12px' }}
              rules={[{ required: true, message: t('sellerCompanyManagement.addPopup.companyValidation') || '' }]}
            >
              <CustomInput placeholder={t('sellerCompanyManagement.addPopup.companyNamePlaceholder') ?? ''} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="contactName" rules={[{ required: true, message: t('sellerCompanyManagement.addPopup.contactNameValidation') || '' }]}>
              <CustomInput placeholder={t('sellerCompanyManagement.addPopup.contactNamePlaceholder') ?? ''} />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="email" rules={[{ type: 'email', required: true, message: t('sellerCompanyManagement.addPopup.emailValidation') || '' }]}>
              <CustomInput placeholder={t('sellerCompanyManagement.addPopup.emailPlaceholder') ?? ''} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="contactPhone" rules={[{ required: true, message: t('sellerCompanyManagement.addPopup.contactPhoneValidation') || '' }]}>
              <CustomInput placeholder={t('sellerCompanyManagement.addPopup.contactPhonePlaceholder') ?? ''} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name="equipmentIds" rules={[{ required: true, message: t('sellerCompanyManagement.addPopup.equipmentValidation') || '' }]}>
              <CustomTreeSelect {...tProps} />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Button isLoading={loading} type="submit" style={{ width: '100%' }} label={t('common.publish')} />
          </Col>
        </Row>
      </Form>
    </LightboxContainer>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { AddFinancialCompanyProps } from '.';
import { useSecureService } from 'src/hooks/service-hooks';
import { CurrencyModel, MaturityOptionModel, RateCreateModel } from 'src/service';
import LightboxContainer from '../lightBoxContainer/lightBoxContainer';
import { Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/button';

import styles from './addFinancialCompany.module.css';
import { Typography } from 'src/components/typography';
import { allowOnlyDecimals } from 'src/utils/string';

export const AddFinancialCompany = (props: AddFinancialCompanyProps) => {
  const { model, show, onCloseClick } = props;
  const { doGetCurrencies, doGetMaturityOptions, doUpdateFinancialCompany, doCreateFinancialCompany } = useSecureService();
  const [currencies, setCurrencies] = useState<CurrencyModel[] | undefined>(undefined);
  const [maturityOptions, setMaturityOptions] = useState<MaturityOptionModel[] | undefined>(undefined);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    const getParams = async () => {
      const currs = await doGetCurrencies();
      const mopts = await doGetMaturityOptions();
      setCurrencies(currs);
      setMaturityOptions(mopts);
    };
    getParams();
    //eslint-disable-next-line
  }, []);

  const Inputs = useMemo(() => {
    if (!maturityOptions || !currencies) return undefined;
    const cols: JSX.Element[] = [];
    for (let index = 0; index < currencies.length; index++) {
      var rowTitle = (
        <Col xs={4} className={styles.tableColumn} style={{ paddingLeft: '12px', paddingTop: '18px' }}>
          <Typography fontFamily="SemiBold" style={{ width: '80px' }}>
            {currencies?.[index].name}
          </Typography>
        </Col>
      );
      cols.push(rowTitle);
      for (let mopt = 0; mopt < maturityOptions.length; mopt++) {
        const formItemName = index.toString() + '-' + mopt.toString();
        const element = (
          <Col xs={5} className={styles.tableColumn}>
            <Form.Item
              name={formItemName}
              rules={[
                {
                  required: true,
                  message: t('financialManagement.addPopup.rateValidation') || ''
                },
                {
                  validator: (rule, val: string) => {
                    var valParts = val.split(',');
                    if (valParts[0].length > 3) {
                      return Promise.reject(t('financialManagement.addPopup.rateValidation'));
                    }
                    if (valParts[1] && valParts[1].length > 2) return Promise.reject(t('financialManagement.addPopup.rateValidation'));
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input placeholder={'%'} />
            </Form.Item>
          </Col>
        );
        cols.push(element);
      }
    }
    return cols;
    //eslint-disable-next-line
  }, [currencies, maturityOptions]);

  const formFinishHandler = async (formData: any) => {
    const rates: Array<RateCreateModel> = [];
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(formData, key)) {
        const value = formData[key];
        if (typeof value === 'string') {
          const arr = key.split('-');
          if (arr.length > 1) {
            const currencyIndex = Number.parseInt(arr[0]);
            const moptIndex = Number.parseInt(arr[1]);
            const rate: RateCreateModel = {
              currencyId: currencies?.[currencyIndex].id,
              maturityOptionId: maturityOptions?.[moptIndex].id,
              rateValue: Number.parseFloat(value.replace(',', '.'))
            };
            rates.push(rate);
          }
        }
      }
    }
    if (!rates || rates.length === 0) return;
    if (model) {
      await doUpdateFinancialCompany({
        id: model.id,
        name: formData['name'],
        rates: rates
      });
    } else {
      await doCreateFinancialCompany({
        name: formData['name'],
        rates: rates
      });
    }
    props.onCloseClick(true);
  };

  useEffect(() => {
    if (!show || !currencies || !maturityOptions) return;
    form.resetFields();
    if (!model) return;
    form.setFieldValue('name', model.name);
    model.rates?.forEach((element) => {
      const currency = currencies.findIndex((x) => x.id === element.currencyId);
      const maturityOption = maturityOptions.findIndex((x) => x.id === element.maturityOptionId);
      form.setFieldValue(currency.toString() + '-' + maturityOption.toString(), element.rateValue?.toLocaleString('tr-TR', { minimumFractionDigits: 2 }));
    });
    //eslint-disable-next-line
  }, [model, currencies, maturityOptions, show]);

  const onValuesChange = (changedValues: any, values: any) => {
    for (const key in changedValues) {
      if (Object.prototype.hasOwnProperty.call(changedValues, key)) {
        if (key.includes('-')) {
          const newValue = allowOnlyDecimals(changedValues[key]);
          form.setFieldValue(key, newValue);
        }
      }
    }
  };

  return (
    <LightboxContainer title={t('financialManagement.addPopup.title')} show={show} onCloseClick={onCloseClick}>
      <Form form={form} onValuesChange={onValuesChange} onFinish={formFinishHandler}>
        <div>
          <Form.Item name="name" className={styles.tableColumn} style={{ marginTop: '12px' }}>
            <Input placeholder={t('financialManagement.addPopup.companyNamePlaceholder') ?? ''} />
          </Form.Item>
        </div>
        <Row gutter={[8, 8]}>
          <Col xs={4} className={styles.tableColumn}>
            <Typography style={{ width: '80px' }} color="color4" fontFamily="SemiBold" size={20}></Typography>
          </Col>
          <Col xs={5} className={styles.tableColumn}>
            <Typography color="color4" fontFamily="SemiBold" size={20}>
              {maturityOptions?.[0].name}
            </Typography>
          </Col>
          <Col xs={5} className={styles.tableColumn}>
            <Typography color="color4" fontFamily="SemiBold" size={20} className={styles.tableColumn}>
              {maturityOptions?.[1].name}
            </Typography>
          </Col>
          <Col xs={5} className={styles.tableColumn}>
            <Typography color="color4" fontFamily="SemiBold" size={20} className={styles.tableColumn}>
              {maturityOptions?.[2].name}
            </Typography>
          </Col>
          <Col xs={5} className={styles.tableColumn}>
            <Typography color="color4" fontFamily="SemiBold" size={20} className={styles.tableColumn}>
              {maturityOptions?.[3].name}
            </Typography>
          </Col>
          {Inputs}
          <Col xs={24}>
            <Button type="submit" style={{ width: '100%' }} label={t('common.publish')} />
          </Col>
        </Row>
      </Form>
    </LightboxContainer>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FinancialCompanyModel } from './FinancialCompanyModel';
import {
    FinancialCompanyModelFromJSON,
    FinancialCompanyModelFromJSONTyped,
    FinancialCompanyModelToJSON,
} from './FinancialCompanyModel';

/**
 * 
 * @export
 * @interface FinancialCompanyModelPagedDataModel
 */
export interface FinancialCompanyModelPagedDataModel {
    /**
     * 
     * @type {number}
     * @memberof FinancialCompanyModelPagedDataModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialCompanyModelPagedDataModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialCompanyModelPagedDataModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialCompanyModelPagedDataModel
     */
    readonly pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialCompanyModelPagedDataModel
     */
    itemCount?: number;
    /**
     * 
     * @type {Array<FinancialCompanyModel>}
     * @memberof FinancialCompanyModelPagedDataModel
     */
    data?: Array<FinancialCompanyModel> | null;
}

/**
 * Check if a given object implements the FinancialCompanyModelPagedDataModel interface.
 */
export function instanceOfFinancialCompanyModelPagedDataModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FinancialCompanyModelPagedDataModelFromJSON(json: any): FinancialCompanyModelPagedDataModel {
    return FinancialCompanyModelPagedDataModelFromJSONTyped(json, false);
}

export function FinancialCompanyModelPagedDataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialCompanyModelPagedDataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'pageCount': !exists(json, 'pageCount') ? undefined : json['pageCount'],
        'itemCount': !exists(json, 'itemCount') ? undefined : json['itemCount'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(FinancialCompanyModelFromJSON)),
    };
}

export function FinancialCompanyModelPagedDataModelToJSON(value?: FinancialCompanyModelPagedDataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'itemCount': value.itemCount,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(FinancialCompanyModelToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ErrorModel } from './ErrorModel';
import {
    ErrorModelFromJSON,
    ErrorModelFromJSONTyped,
    ErrorModelToJSON,
} from './ErrorModel';
import type { OfferGuestViewModel } from './OfferGuestViewModel';
import {
    OfferGuestViewModelFromJSON,
    OfferGuestViewModelFromJSONTyped,
    OfferGuestViewModelToJSON,
} from './OfferGuestViewModel';

/**
 * 
 * @export
 * @interface OfferGuestViewModelIEnumerableValidatedModel
 */
export interface OfferGuestViewModelIEnumerableValidatedModel {
    /**
     * 
     * @type {ErrorModel}
     * @memberof OfferGuestViewModelIEnumerableValidatedModel
     */
    error?: ErrorModel;
    /**
     * 
     * @type {boolean}
     * @memberof OfferGuestViewModelIEnumerableValidatedModel
     */
    readonly success?: boolean;
    /**
     * 
     * @type {Array<OfferGuestViewModel>}
     * @memberof OfferGuestViewModelIEnumerableValidatedModel
     */
    data?: Array<OfferGuestViewModel> | null;
}

/**
 * Check if a given object implements the OfferGuestViewModelIEnumerableValidatedModel interface.
 */
export function instanceOfOfferGuestViewModelIEnumerableValidatedModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OfferGuestViewModelIEnumerableValidatedModelFromJSON(json: any): OfferGuestViewModelIEnumerableValidatedModel {
    return OfferGuestViewModelIEnumerableValidatedModelFromJSONTyped(json, false);
}

export function OfferGuestViewModelIEnumerableValidatedModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferGuestViewModelIEnumerableValidatedModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : ErrorModelFromJSON(json['error']),
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(OfferGuestViewModelFromJSON)),
    };
}

export function OfferGuestViewModelIEnumerableValidatedModelToJSON(value?: OfferGuestViewModelIEnumerableValidatedModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ErrorModelToJSON(value.error),
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(OfferGuestViewModelToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RateViewModel
 */
export interface RateViewModel {
    /**
     * 
     * @type {number}
     * @memberof RateViewModel
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof RateViewModel
     */
    currencyId?: number;
    /**
     * 
     * @type {number}
     * @memberof RateViewModel
     */
    maturityOptionId?: number;
    /**
     * 
     * @type {number}
     * @memberof RateViewModel
     */
    rateValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RateViewModel
     */
    isActive?: boolean;
}

/**
 * Check if a given object implements the RateViewModel interface.
 */
export function instanceOfRateViewModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RateViewModelFromJSON(json: any): RateViewModel {
    return RateViewModelFromJSONTyped(json, false);
}

export function RateViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'currencyId': !exists(json, 'currencyId') ? undefined : json['currencyId'],
        'maturityOptionId': !exists(json, 'maturityOptionId') ? undefined : json['maturityOptionId'],
        'rateValue': !exists(json, 'rateValue') ? undefined : json['rateValue'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function RateViewModelToJSON(value?: RateViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'currencyId': value.currencyId,
        'maturityOptionId': value.maturityOptionId,
        'rateValue': value.rateValue,
        'isActive': value.isActive,
    };
}


import { useEffect, useState } from 'react';
import { themeAtom } from '../utils/atoms/themeAtom';
import { useAtomValue } from 'jotai';
import { themeLight } from 'src/styles/themeLight';
import { Theme, Themes } from 'src/types/theme';

export function useTheme() {
  const activeTheme = useAtomValue(themeAtom);
  const [theme, setTheme] = useState<Theme>(themeLight);

  const themes: Record<Themes, Theme> = {
    light: themeLight
  };

  useEffect(() => {
    setTheme(themes[activeTheme]);
    //eslint-disable-next-line
  }, [activeTheme]);

  return theme;
}

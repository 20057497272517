import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { useMediaQueryVariables } from "src/hooks/useMediaQuery";
import { Typography } from 'src/components/typography';
import { WhiteCard } from 'src/components/whiteCard';
//import { loadingAtom } from "src/utils/atoms/global-atoms";
//import { useAtom } from "jotai";
import { AdminContainer } from 'src/components/adminContainer';
import { Button } from 'src/components/button';
import { CSVLink } from 'react-csv';
import DataTable from 'src/components/dataTable/data-table';
import { PaginationType, useSecureService } from 'src/hooks/service-hooks';
import { Data } from 'react-csv/components/CommonPropTypes';
import { ApplicationFormViewModel, BasicModel } from 'src/service';
import { useAtom } from 'jotai';
import { loadingAtom } from 'src/utils/atoms/global-atoms';
import { TablePaginationConfig, Tooltip } from 'antd';
import styles from './DashboardView.module.css';
import { SeeOffers } from 'src/components/modals/seeOffers';
import { UNNAMED_FINANCIAL_COMPANY_PROMOTION_TEXT } from 'src/utils/constants';
import _ from 'lodash';

export const DashboardView = () => {
  //const { isTabletOrMobile } = useMediaQueryVariables();
  const { doGetAllApplications } = useSecureService();
  const [loading] = useAtom(loadingAtom);
  const [offerModalVisible, setOfferModalVisible] = useState(false);
  const [allRows, setAllRows] = useState<Data>([]);
  const [applicationForms, setApplicationforms] = useState<ApplicationFormViewModel[] | undefined>(undefined);
  const [selectedModel, setSelectedModel] = useState<ApplicationFormViewModel | undefined>(undefined);
  const { t } = useTranslation();
  const initialColumnsArr = [
    {
      title: t('dashboard.table.state-date'),
      key: 'date',
      render: (text: string, record: ApplicationFormViewModel) => {
        let applied = false;
        if (record.offers && record.offers.length > 0) applied = true;
        return (
          <div className={styles.multidatacell}>
            <Typography color={applied ? 'color6' : 'color9'}>{applied ? 'Yaptı' : 'Yapmadı'}</Typography>
            <Typography>{record.createdOn?.toLocaleDateString()}</Typography>
          </div>
        );
      }
    },
    {
      title: t('dashboard.table.name-id'),
      key: 'name',
      render: (text: string, record: ApplicationFormViewModel) => {
        return (
          <div className={styles.multidatacell}>
            <Typography>{record.fullName}</Typography>
            <Typography>{record.id}</Typography>
          </div>
        );
      }
    },
    {
      title: t('dashboard.table.phone'),
      key: 'phone',
      dataIndex: 'phone',
      render: (record: any) => {
        return <Typography>{record}</Typography>;
      }
    },
    {
      title: t('dashboard.table.email'),
      key: 'email',
      dataIndex: 'email',
      render: (record: any) => {
        return <Typography>{record}</Typography>;
      }
    },
    {
      title: t('dashboard.table.equipmentMainCategory'),
      key: 'equipmentGroup',
      dataIndex: 'equipmentGroup',
      render: (record: BasicModel) => {
        return <Typography>{record?.name ?? 'Diğer'}</Typography>;
      }
    },
    {
      title: t('dashboard.table.equipmentSubCategory'),
      key: 'equipment',
      dataIndex: 'equipment',
      render: (record: BasicModel) => {
        return <Typography>{record?.name ?? 'Diğer'}</Typography>;
      }
    },
    {
      title: t('dashboard.table.sellerCompany'),
      key: 'sellerCompany',
      render: (text: string, record: ApplicationFormViewModel) => {
        return (
          <Typography>
            {record?.sellerCompany?.id && record?.sellerCompany?.id === 9999
              ? `${record.sellerCompany.name} - ${record.sellerCompanyOther ? record.sellerCompanyOther : t('dashboard.table.sellerEmpty')}`
              : record.sellerCompany?.name}
          </Typography>
        );
      }
    },
    {
      title: t('dashboard.table.company'),
      key: 'company',
      dataIndex: 'company',
      render: (text: string) => {
        return <Typography>{_.isEmpty(text) ? '-' : text}</Typography>;
      }
    },
    {
      title: t('dashboard.table.vkn'),
      key: 'vkn',
      dataIndex: 'vkn',
      render: (record: any) => {
        return <Typography>{record}</Typography>;
      }
    },
    {
      title: t('dashboard.table.description'),
      key: 'description',
      dataIndex: 'description',
      render: (record: any) => {
        return (
          <Tooltip title={record}>
            <Typography style={{ cursor: 'pointer' }}>{t('common.view')}</Typography>
          </Tooltip>
        );
      }
    },
    {
      title: t('dashboard.table.offer'),
      key: 'offers',
      render: (val: string, model: ApplicationFormViewModel) => {
        const record = model.offers;
        const anyAcceptedOffer = record?.find((x) => x.isAccepted);
        const text = anyAcceptedOffer ? anyAcceptedOffer.financialCompany?.name || UNNAMED_FINANCIAL_COMPANY_PROMOTION_TEXT : undefined;
        const color = text ? '#35B934' : '#BABBBC';
        return (
          <div
            onClick={() => {
              if (!text) return;
              setSelectedModel(model);
              setOfferModalVisible(true);
            }}
            style={{
              backgroundColor: color,
              padding: '4px',
              borderRadius: '24px',
              display: 'flex',
              maxWidth: '140px',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: text ? 'pointer' : 'auto'
            }}
          >
            <Typography isCenter color="color1">
              {text || 'BOŞ'}
            </Typography>
          </div>
        );
      }
    }
  ];
  const [callAgain, setCallAgain] = useState(0);
  const [itemCount, setItemCount] = useState<number | undefined>(1);
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    pageSize: 10
  });
  const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  useEffect(() => {
    const getAllApplicationForms = async () => {
      const result = await doGetAllApplications({
        pageSize: pagination.pageSize,
        pageNo: pagination.current
      });
      setItemCount(result?.itemCount);
      setApplicationforms(result?.data || undefined);
    };
    getAllApplicationForms();
    //eslint-disable-next-line
  }, [callAgain]);

  useEffect(() => {
    if (!allRows || allRows.length === 0) return;
    csvRef.current?.link.click();
  }, [allRows]);

  let headers = [
    { label: 'id', key: 'id' },
    { label: 'Ad-Soyad', key: 'fullName' },
    { label: 'Email', key: 'email' },
    { label: 'Telefon', key: 'phone' },
    { label: 'Vkn', key: 'vkn' },
    { label: 'Açıklama', key: 'description' },
    { label: 'Başvuran Firma adı', key: 'company' },
    { label: 'Satıcı Firma adı', key: 'sellerCompanyName' },
    { label: 'Ekipman', key: 'equipment' },
    { label: 'Vade', key: 'maturityOptionName' },
    { label: 'Kur', key: 'currencyName' },
    { label: 'Mal Bedeli', key: 'equipmentValue' },
    { label: 'Peşinat', key: 'payment' },
    { label: 'Teklif 1 firma', key: 'financialCompanyName0' },
    { label: 'Teklif 1 toplam ödeme', key: 'totalPaymentValue0' },
    { label: 'Teklif 1 aylık ödeme', key: 'monthlyPaymentValue0' },
    { label: 'Teklif 1 secildi?', key: 'isAccepted0' },
    { label: 'Teklif 2 firma', key: 'financialCompanyName1' },
    { label: 'Teklif 2 toplam ödeme', key: 'totalPaymentValue1' },
    { label: 'Teklif 2 aylık ödeme', key: 'monthlyPaymentValue1' },
    { label: 'Teklif 2 secildi?', key: 'isAccepted1' },
    { label: 'Teklif 3 firma', key: 'financialCompanyName2' },
    { label: 'Teklif 3 toplam ödeme', key: 'totalPaymentValue2' },
    { label: 'Teklif 3 aylık ödeme', key: 'monthlyPaymentValue2' },
    { label: 'Teklif 3 secildi?', key: 'isAccepted2' }
  ];

  const exportClick = async () => {
    if (!itemCount || itemCount <= 0) {
      return;
    }
    const result = await doGetAllApplications({ pageNo: 1, pageSize: itemCount });
    if (result?.data?.length !== undefined && result?.data?.length > 0) {
      let manipulatedData: Data = [];
      result.data.forEach((item: ApplicationFormViewModel) => {
        const newItem: any = item;
        newItem.id = item.id;
        newItem.fullName = item.fullName;
        newItem.email = item.email;
        newItem.phone = item.phone;
        newItem.vkn = item.vkn;
        newItem.description = item.description;
        newItem.sellerCompanyName = item.sellerCompany?.name || item.sellerCompanyOther;
        newItem.equipmentGroup = item.equipmentGroup?.name;
        newItem.equipment = item.equipment?.name;
        newItem.maturityOptionName = item.offers?.[0]?.maturityOption?.name;
        newItem.currencyName = item.offers?.[0]?.currency?.name;
        newItem.equipmentValue = item.offers?.[0]?.equipmentValue;
        newItem.payment = item.offers?.[0]?.payment;
        newItem.financialCompanyName0 = item.offers?.[0]?.financialCompany?.name;
        newItem.totalPaymentValue0 = item.offers?.[0]?.totalPaymentValue;
        newItem.monthlyPaymentValue0 = item.offers?.[0]?.monthlyPaymentValue;
        newItem.isAccepted0 = item.offers?.[0]?.isAccepted;
        newItem.financialCompanyName1 = item.offers?.[1]?.financialCompany?.name;
        newItem.totalPaymentValue1 = item.offers?.[1]?.totalPaymentValue;
        newItem.monthlyPaymentValue1 = item.offers?.[1]?.monthlyPaymentValue;
        newItem.isAccepted1 = item.offers?.[1]?.isAccepted;
        newItem.financialCompanyName2 = item.offers?.[2]?.financialCompany?.name;
        newItem.totalPaymentValue2 = item.offers?.[2]?.totalPaymentValue;
        newItem.monthlyPaymentValue2 = item.offers?.[2]?.monthlyPaymentValue;
        newItem.isAccepted2 = item.offers?.[2]?.isAccepted;

        manipulatedData.push(newItem);
      });

      setAllRows(manipulatedData);
    }
  };

  //const [loading] = useAtom(loadingAtom);
  const handleChange = (tablePagination: TablePaginationConfig) => {
    setPagination((curr) => {
      return { ...curr, current: tablePagination.current };
    });
    setCallAgain((val) => val + 1);
  };
  return (
    <AdminContainer activeNode={'/dashboard'}>
      <WhiteCard>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography size={20} fontFamily="SemiBold">
            {t('dashboard.page.title')}
          </Typography>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button onPress={exportClick} label={t('common.export-as-csv')} />
          </div>
        </div>
        <CSVLink filename="basvurular.csv" ref={csvRef} data={allRows} headers={headers} separator={';'} />
        <DataTable
          onChange={handleChange}
          columns={initialColumnsArr}
          dataSource={applicationForms}
          loading={loading}
          pagination={{ ...pagination, position: ['bottomCenter'], total: itemCount, showSizeChanger: false }}
        />
      </WhiteCard>
      <SeeOffers
        model={selectedModel}
        show={offerModalVisible}
        onCloseClick={() => {
          setOfferModalVisible(false);
        }}
      />
    </AdminContainer>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ErrorModel } from './ErrorModel';
import {
    ErrorModelFromJSON,
    ErrorModelFromJSONTyped,
    ErrorModelToJSON,
} from './ErrorModel';
import type { SellerCompanyListModelPagedDataModel } from './SellerCompanyListModelPagedDataModel';
import {
    SellerCompanyListModelPagedDataModelFromJSON,
    SellerCompanyListModelPagedDataModelFromJSONTyped,
    SellerCompanyListModelPagedDataModelToJSON,
} from './SellerCompanyListModelPagedDataModel';

/**
 * 
 * @export
 * @interface SellerCompanyListModelPagedDataModelValidatedModel
 */
export interface SellerCompanyListModelPagedDataModelValidatedModel {
    /**
     * 
     * @type {ErrorModel}
     * @memberof SellerCompanyListModelPagedDataModelValidatedModel
     */
    error?: ErrorModel;
    /**
     * 
     * @type {boolean}
     * @memberof SellerCompanyListModelPagedDataModelValidatedModel
     */
    readonly success?: boolean;
    /**
     * 
     * @type {SellerCompanyListModelPagedDataModel}
     * @memberof SellerCompanyListModelPagedDataModelValidatedModel
     */
    data?: SellerCompanyListModelPagedDataModel;
}

/**
 * Check if a given object implements the SellerCompanyListModelPagedDataModelValidatedModel interface.
 */
export function instanceOfSellerCompanyListModelPagedDataModelValidatedModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SellerCompanyListModelPagedDataModelValidatedModelFromJSON(json: any): SellerCompanyListModelPagedDataModelValidatedModel {
    return SellerCompanyListModelPagedDataModelValidatedModelFromJSONTyped(json, false);
}

export function SellerCompanyListModelPagedDataModelValidatedModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerCompanyListModelPagedDataModelValidatedModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : ErrorModelFromJSON(json['error']),
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': !exists(json, 'data') ? undefined : SellerCompanyListModelPagedDataModelFromJSON(json['data']),
    };
}

export function SellerCompanyListModelPagedDataModelValidatedModelToJSON(value?: SellerCompanyListModelPagedDataModelValidatedModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ErrorModelToJSON(value.error),
        'data': SellerCompanyListModelPagedDataModelToJSON(value.data),
    };
}


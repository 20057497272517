/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BasicModelIEnumerableValidatedModel,
  EquipmentGroupIEnumerableValidatedModel,
  EquipmentGroupListModelIEnumerableValidatedModel,
  ErrorModel,
} from '../models';
import {
    BasicModelIEnumerableValidatedModelFromJSON,
    BasicModelIEnumerableValidatedModelToJSON,
    EquipmentGroupIEnumerableValidatedModelFromJSON,
    EquipmentGroupIEnumerableValidatedModelToJSON,
    EquipmentGroupListModelIEnumerableValidatedModelFromJSON,
    EquipmentGroupListModelIEnumerableValidatedModelToJSON,
    ErrorModelFromJSON,
    ErrorModelToJSON,
} from '../models';

/**
 * 
 */
export class EquipmentGroupApi extends runtime.BaseAPI {

    /**
     */
    async equipmentGroupAllGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EquipmentGroupIEnumerableValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment-group/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentGroupIEnumerableValidatedModelFromJSON(jsonValue));
    }

    /**
     */
    async equipmentGroupAllGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EquipmentGroupIEnumerableValidatedModel> {
        const response = await this.equipmentGroupAllGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Guest
     */
    async equipmentGroupGuestAllGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasicModelIEnumerableValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment-group/guest-all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasicModelIEnumerableValidatedModelFromJSON(jsonValue));
    }

    /**
     * Guest
     */
    async equipmentGroupGuestAllGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasicModelIEnumerableValidatedModel> {
        const response = await this.equipmentGroupGuestAllGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Guest
     */
    async equipmentGroupGuestAllWithEquipmentsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EquipmentGroupListModelIEnumerableValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment-group/guest-all-with-equipments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentGroupListModelIEnumerableValidatedModelFromJSON(jsonValue));
    }

    /**
     * Guest
     */
    async equipmentGroupGuestAllWithEquipmentsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EquipmentGroupListModelIEnumerableValidatedModel> {
        const response = await this.equipmentGroupGuestAllWithEquipmentsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async equipmentGroupUpdateRanksGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment-group/update-ranks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async equipmentGroupUpdateRanksGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.equipmentGroupUpdateRanksGetRaw(initOverrides);
    }

}

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { useMediaQueryVariables } from "src/hooks/useMediaQuery";
import { Typography } from 'src/components/typography';
import { WhiteCard } from 'src/components/whiteCard';
import { loadingAtom } from 'src/utils/atoms/global-atoms';
import { useAtom } from 'jotai';
import { AdminContainer } from 'src/components/adminContainer';
import { PaginationType, useSecureService } from 'src/hooks/service-hooks';
import { EditOutlined, PlusCircleFilled, DeleteOutlined } from '@ant-design/icons';
import { CurrencyModel, FinancialCompanyModel, MaturityOptionModel, RateViewModel } from 'src/service';
import { Space, TablePaginationConfig, Tooltip } from 'antd';
import DataTable from 'src/components/dataTable/data-table';
import { AddFinancialCompany } from 'src/components/modals/addFinancialCompany';
import { Button } from 'src/components/button';
import { CSVLink } from 'react-csv';
import { Data } from 'react-csv/components/CommonPropTypes';
import { UNNAMED_FINANCIAL_COMPANY_LABEL } from 'src/utils/constants';

export const FinancialManagementView = () => {
  //const { isTabletOrMobile } = useMediaQueryVariables();
  const { t } = useTranslation();
  const [loading] = useAtom(loadingAtom);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [currencies, setCurrencies] = useState<CurrencyModel[] | undefined>(undefined);
  const [maturityOptions, setMaturityOptions] = useState<MaturityOptionModel[] | undefined>(undefined);
  const [financialCompanies, setFinancialCompanies] = useState<FinancialCompanyModel[] | undefined>(undefined);
  const [selectedModel, setSelectedModel] = useState<FinancialCompanyModel | undefined>(undefined);
  const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [allRows, setAllRows] = useState<Data>([]);
  const initialColumnsArr = [
    {
      title: t('financialManagement.table.id'),
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: t('financialManagement.table.name'),
      dataIndex: 'name',
      key: 'name',
      render: (val: string, row: FinancialCompanyModel) => <>{val ? val : UNNAMED_FINANCIAL_COMPANY_LABEL + '/ ' + row.id}</>
    },
    {
      title: t('financialManagement.table.updatedOn'),
      dataIndex: 'updatedOn',
      key: 'updatedOn',
      render: (val: any) => <>{val.toLocaleDateString()}</>
    }
  ];
  const [columns, setColumns] = useState<any[]>(initialColumnsArr);
  const [callAgain, setCallAgain] = useState(0);
  const [itemCount, setItemCount] = useState<number | undefined>(1);
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    pageSize: 10
  });

  let headers = [
    { label: 'id', key: 'id' },
    { label: 'name', key: 'name' },
    { label: 'isActive', key: 'isActive' },
    { label: 'updatedOn', key: 'updatedOn' },
    { label: '12 Ay - USD', key: '12 Ay - USD' },
    { label: '12 Ay - EUR', key: '12 Ay - EUR' },
    { label: '12 Ay - TL', key: '12 Ay - TL' },
    { label: '24 Ay - USD', key: '24 Ay - USD' },
    { label: '24 Ay - EUR', key: '24 Ay - EUR' },
    { label: '24 Ay - TL', key: '24 Ay - TL' },
    { label: '36 Ay - USD', key: '36 Ay - USD' },
    { label: '36 Ay - EUR', key: '36 Ay - EUR' },
    { label: '36 Ay - TL', key: '36 Ay - TL' },
    { label: '48 Ay - USD', key: '48 Ay - USD' },
    { label: '48 Ay - EUR', key: '48 Ay - EUR' },
    { label: '48 Ay - TL', key: '48 Ay - TL' }
  ];
  const { doGetCurrencies, doGetMaturityOptions, doGetAllFinancialCompanies, doDeactivateFinancialCompany } = useSecureService();

  const getCurrencyComponents = (val: RateViewModel[], maturityOptionIndex: number) => {
    console.log(val, maturityOptionIndex);
    if (!maturityOptions || !maturityOptions[maturityOptionIndex]) return <></>;
    console.log('continuing...');
    const allCurrencies = val.filter((x) => x.maturityOptionId === maturityOptions[maturityOptionIndex].id);
    console.log('allCurr: ', allCurrencies);
    const monthCurrencies = allCurrencies.map((currItem) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', margin: '4px' }}>
          <span>{currencies?.find((x) => x.id === currItem.currencyId)?.name}</span>
          <span>%{currItem.rateValue?.toLocaleString('tr-TR', { minimumFractionDigits: 2 })}</span>
        </div>
      );
    });

    return <div style={{ display: 'flex', flexDirection: 'row' }}>{monthCurrencies}</div>;
  };

  useEffect(() => {
    const getCurrenciesAndMaturityOptions = async () => {
      const currs = await doGetCurrencies();
      const result = await doGetMaturityOptions();
      setMaturityOptions(result);
      setCurrencies(currs);
    };

    const getAllFinancialCompanies = async () => {
      const result = await doGetAllFinancialCompanies({
        pageSize: pagination.pageSize,
        pageNo: pagination.current
      });
      setItemCount(result?.itemCount);
      setFinancialCompanies(result?.data || undefined);
    };
    getCurrenciesAndMaturityOptions();
    getAllFinancialCompanies();
    //eslint-disable-next-line
  }, [callAgain]);

  useEffect(() => {
    if (!maturityOptions) return;
    const newCol = [...columns];
    if (!columns.some((x) => x.key === 'rates12')) {
      newCol.push({
        title: t('financialManagement.table.twelveMonths'),
        dataIndex: 'rates',
        key: 'rates12',
        render: (val: RateViewModel[]) => getCurrencyComponents(val, 0)
      });
    }

    if (!columns.some((x) => x.key === 'rates24')) {
      newCol.push({
        title: t('financialManagement.table.twFourMonths'),
        dataIndex: 'rates',
        key: 'rates24',
        render: (val: RateViewModel[]) => getCurrencyComponents(val, 1)
      });
    }

    if (!columns.some((x) => x.key === 'rates36')) {
      newCol.push({
        title: t('financialManagement.table.thSixMonths'),
        dataIndex: 'rates',
        key: 'rates36',
        render: (val: RateViewModel[]) => getCurrencyComponents(val, 2)
      });
    }

    if (!columns.some((x) => x.key === 'rates48')) {
      newCol.push({
        title: t('financialManagement.table.thSixPlusMonths'),
        dataIndex: 'rates',
        key: 'rates48',
        render: (val: RateViewModel[]) => getCurrencyComponents(val, 3)
      });
    }

    if (!columns.some((x) => x.key === 'action')) {
      newCol.push({
        title: '',
        key: 'action',
        render: (text: string, record: any) => (
          <Space size="middle">
            <Tooltip title={t('common.edit')}>
              <EditOutlined
                onClick={() => {
                  setSelectedModel(record as FinancialCompanyModel);
                  setAddModalVisible(true);
                }}
                rev={undefined}
              />
            </Tooltip>
            <Tooltip title={t('common.delete')}>
              <DeleteOutlined
                onClick={async () => {
                  await doDeactivateFinancialCompany(record.id);
                }}
                rev={undefined}
              />
            </Tooltip>
          </Space>
        )
      });
    }

    setColumns(newCol);
    //eslint-disable-next-line
  }, [maturityOptions, currencies]);

  const handleChange = (tablePagination: TablePaginationConfig) => {
    setPagination((curr) => {
      return { ...curr, current: tablePagination.current };
    });
    setCallAgain((val) => val + 1);
  };

  const exportClick = async () => {
    if (!itemCount || itemCount <= 0) {
      return;
    }
    const result = await doGetAllFinancialCompanies({ pageNo: 1, pageSize: itemCount });
    if (result?.data?.length !== undefined && result?.data?.length > 0) {
      let manipulatedData: Data = [];
      result.data.forEach((item: FinancialCompanyModel) => {
        const newItem: any = item;
        newItem.id = item.id;
        newItem.name = item.name ? item.name : UNNAMED_FINANCIAL_COMPANY_LABEL + '/ ' + item.id;
        newItem.isActive = item.isActive;
        newItem.updatedOn = item.updatedOn;
        item.rates?.forEach((rate) => {
          const currencyName = currencies?.find((x) => x.id === rate.currencyId)?.name;
          const maturityOptionName = maturityOptions?.find((x) => x.id === rate.maturityOptionId)?.name;
          newItem[maturityOptionName + ' - ' + currencyName] = rate.rateValue;
          newItem[maturityOptionName + ' - ' + currencyName + ' active?'] = rate.isActive;
        });
        manipulatedData.push(newItem);
      });

      setAllRows(manipulatedData);
    }
  };

  useEffect(() => {
    if (!allRows || allRows.length === 0) return;
    csvRef.current?.link.click();
  }, [allRows]);

  return (
    <AdminContainer activeNode={'/financial-management'}>
      <WhiteCard>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography size={20} fontFamily="SemiBold">
            {t('financialManagement.page.title')}
          </Typography>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button
              label={t('financialManagement.page.ctaLabel')}
              onPress={() => {
                setSelectedModel(undefined);
                setAddModalVisible(true);
              }}
            >
              <PlusCircleFilled style={{ color: '#FFFFFF', marginRight: '8px', fontSize: '18px' }} rev={undefined} />
            </Button>
            <Button onPress={exportClick} label={t('common.export-as-csv')} />
          </div>
        </div>
        <CSVLink filename="finansal_kurumlar.csv" ref={csvRef} data={allRows} headers={headers} separator={';'} />
        <DataTable
          onChange={handleChange}
          columns={columns}
          dataSource={financialCompanies}
          loading={loading}
          pagination={{ ...pagination, position: ['bottomCenter'], total: itemCount, showSizeChanger: false }}
        />
      </WhiteCard>
      <AddFinancialCompany
        model={selectedModel}
        show={addModalVisible}
        onCloseClick={(refresh) => {
          if (refresh) {
            setCallAgain((oldVal) => oldVal + 1);
          }
          setAddModalVisible(false);
        }}
      ></AddFinancialCompany>
    </AdminContainer>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'src/components/view';
import { useStyle } from 'src/hooks/useStyles';
import { margin } from 'src/styles/layoutStyles';
import { Typography } from '../typography';
import { headerLabelKeys } from './header.constants';
import { HeaederLogo } from './header.logo';
import { baseStyles } from './header.styles';
export const HeaderDesktop = () => {
  const styles = useStyle(baseStyles);
  const { t } = useTranslation();

  const renderLabels = (item: { label: string; to: string }) => {
    return (
      <a
        href={item.to}
        style={{
          textDecoration: 'none'
        }}
      >
        <Typography fontFamily="Medium" style={margin(8, 'right')} color={'color5'}>
          {t(item.label)}
        </Typography>
      </a>
    );
  };
  return (
    <View style={styles.container}>
      <HeaederLogo />
      <View>{headerLabelKeys.map(renderLabels)}</View>
    </View>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorModel,
  SellerCompanyCreateModel,
  SellerCompanyDetailModelValidatedModel,
  SellerCompanyListModelPagedDataModelValidatedModel,
  SellerCompanySearchModel,
  SellerCompanyUpdateModel,
} from '../models';
import {
    ErrorModelFromJSON,
    ErrorModelToJSON,
    SellerCompanyCreateModelFromJSON,
    SellerCompanyCreateModelToJSON,
    SellerCompanyDetailModelValidatedModelFromJSON,
    SellerCompanyDetailModelValidatedModelToJSON,
    SellerCompanyListModelPagedDataModelValidatedModelFromJSON,
    SellerCompanyListModelPagedDataModelValidatedModelToJSON,
    SellerCompanySearchModelFromJSON,
    SellerCompanySearchModelToJSON,
    SellerCompanyUpdateModelFromJSON,
    SellerCompanyUpdateModelToJSON,
} from '../models';

export interface SellerCompanyAllPostRequest {
    sellerCompanySearchModel?: SellerCompanySearchModel;
}

export interface SellerCompanyCreatePostRequest {
    sellerCompanyCreateModel?: SellerCompanyCreateModel;
}

export interface SellerCompanyDetailSellerCompanyIdGetRequest {
    sellerCompanyId: number;
}

export interface SellerCompanySellerCompanyIdDeleteRequest {
    sellerCompanyId: number;
}

export interface SellerCompanyUpdatePutRequest {
    sellerCompanyUpdateModel?: SellerCompanyUpdateModel;
}

/**
 * 
 */
export class SellerCompanyApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async sellerCompanyAllPostRaw(requestParameters: SellerCompanyAllPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SellerCompanyListModelPagedDataModelValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/seller-company/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SellerCompanySearchModelToJSON(requestParameters.sellerCompanySearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SellerCompanyListModelPagedDataModelValidatedModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async sellerCompanyAllPost(requestParameters: SellerCompanyAllPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SellerCompanyListModelPagedDataModelValidatedModel> {
        const response = await this.sellerCompanyAllPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async sellerCompanyCreatePostRaw(requestParameters: SellerCompanyCreatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/seller-company/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SellerCompanyCreateModelToJSON(requestParameters.sellerCompanyCreateModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async sellerCompanyCreatePost(requestParameters: SellerCompanyCreatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sellerCompanyCreatePostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin
     */
    async sellerCompanyDetailSellerCompanyIdGetRaw(requestParameters: SellerCompanyDetailSellerCompanyIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SellerCompanyDetailModelValidatedModel>> {
        if (requestParameters.sellerCompanyId === null || requestParameters.sellerCompanyId === undefined) {
            throw new runtime.RequiredError('sellerCompanyId','Required parameter requestParameters.sellerCompanyId was null or undefined when calling sellerCompanyDetailSellerCompanyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/seller-company/detail/{sellerCompanyId}`.replace(`{${"sellerCompanyId"}}`, encodeURIComponent(String(requestParameters.sellerCompanyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SellerCompanyDetailModelValidatedModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async sellerCompanyDetailSellerCompanyIdGet(requestParameters: SellerCompanyDetailSellerCompanyIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SellerCompanyDetailModelValidatedModel> {
        const response = await this.sellerCompanyDetailSellerCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async sellerCompanySellerCompanyIdDeleteRaw(requestParameters: SellerCompanySellerCompanyIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerCompanyId === null || requestParameters.sellerCompanyId === undefined) {
            throw new runtime.RequiredError('sellerCompanyId','Required parameter requestParameters.sellerCompanyId was null or undefined when calling sellerCompanySellerCompanyIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/seller-company/{sellerCompanyId}`.replace(`{${"sellerCompanyId"}}`, encodeURIComponent(String(requestParameters.sellerCompanyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async sellerCompanySellerCompanyIdDelete(requestParameters: SellerCompanySellerCompanyIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sellerCompanySellerCompanyIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Admin
     */
    async sellerCompanyUpdatePutRaw(requestParameters: SellerCompanyUpdatePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/seller-company/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SellerCompanyUpdateModelToJSON(requestParameters.sellerCompanyUpdateModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async sellerCompanyUpdatePut(requestParameters: SellerCompanyUpdatePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sellerCompanyUpdatePutRaw(requestParameters, initOverrides);
    }

}

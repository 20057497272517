import { UseStyleVariables } from 'src/hooks/useStyles';
import { bgColor, layoutStyles, padding } from 'src/styles/layoutStyles';

export const baseStyles = (useStyleVariables: UseStyleVariables) => {
  const { theme, mediaQueryVariables } = useStyleVariables;
  const { isTabletOrMobile } = mediaQueryVariables;
  return {
    contentContainer: {
      ...bgColor(theme.colors.color3)
    },
    container: [bgColor(theme.colors.color3), padding(isTabletOrMobile ? 4 : 5, 'vertical'), layoutStyles.spaceBetween, layoutStyles.alignCenter]
  };
};

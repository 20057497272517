/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RateCreateModel
 */
export interface RateCreateModel {
    /**
     * 
     * @type {number}
     * @memberof RateCreateModel
     */
    currencyId?: number;
    /**
     * 
     * @type {number}
     * @memberof RateCreateModel
     */
    maturityOptionId?: number;
    /**
     * 
     * @type {number}
     * @memberof RateCreateModel
     */
    rateValue?: number;
}

/**
 * Check if a given object implements the RateCreateModel interface.
 */
export function instanceOfRateCreateModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RateCreateModelFromJSON(json: any): RateCreateModel {
    return RateCreateModelFromJSONTyped(json, false);
}

export function RateCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currencyId': !exists(json, 'currencyId') ? undefined : json['currencyId'],
        'maturityOptionId': !exists(json, 'maturityOptionId') ? undefined : json['maturityOptionId'],
        'rateValue': !exists(json, 'rateValue') ? undefined : json['rateValue'],
    };
}

export function RateCreateModelToJSON(value?: RateCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currencyId': value.currencyId,
        'maturityOptionId': value.maturityOptionId,
        'rateValue': value.rateValue,
    };
}


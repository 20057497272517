export const colors = {
  green: '#5FD4C3',
  gray: '#F8F9FA',
  darkBlue: '#1A2845',
  lightBlue: '#536EAF',
  white: '#FFFFFF',
  black: '#000000',
  gainsboro: '#D9D9D9',
  nobel: '#9A9A9A',
  error: '#F44336'
};

export function addAlpha(color: string, opacity: number): string {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

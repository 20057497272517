/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorModel,
  UserDetailModel,
  UserLoginModel,
} from '../models';
import {
    ErrorModelFromJSON,
    ErrorModelToJSON,
    UserDetailModelFromJSON,
    UserDetailModelToJSON,
    UserLoginModelFromJSON,
    UserLoginModelToJSON,
} from '../models';

export interface LoginPostRequest {
    userLoginModel?: UserLoginModel;
}

/**
 * 
 */
export class LoginApi extends runtime.BaseAPI {

    /**
     * Guest
     */
    async loginPostRaw(requestParameters: LoginPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLoginModelToJSON(requestParameters.userLoginModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailModelFromJSON(jsonValue));
    }

    /**
     * Guest
     */
    async loginPost(requestParameters: LoginPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailModel> {
        const response = await this.loginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

import { ConfigProvider, TreeSelect } from 'antd';
import { CustomTreeSelectProps } from '.';

export const CustomTreeSelect = (props: CustomTreeSelectProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            controlHeightLG: 48
          }
        }
      }}
    >
      <TreeSelect size="large" {...props} />
    </ConfigProvider>
  );
};

import { useAtom } from 'jotai';
import React from 'react';
import { useStyle } from 'src/hooks/useStyles';
import { menuAtom } from 'src/utils/atoms/menuAtom';
import { Icon } from '../icon';
import { View } from '../view';
import { HeaederLogo } from './header.logo';
import { baseStyles } from './header.styles';

export const HeaderMobile = () => {
  const styles = useStyle(baseStyles);
  const [menuIsOpen, setIsMenuOpen] = useAtom(menuAtom);

  return (
    <View style={styles.container}>
      <HeaederLogo />
      <Icon
        onClick={() => setIsMenuOpen((prev) => !prev)}
        icon={menuIsOpen ? 'close' : 'menu'}
        size={22}
        style={{
          cursor: 'pointer'
        }}
      />
    </View>
  );
};

import { ASSETS_PATH } from '../constants';

export enum ImageNames {
  BOARD = 'png/board.png',
  PROFILE_IMAGE = 'png/profile.png',
  BRIEFCASE = 'png/briefcase.png',
  SEARCH = 'png/search.png',
  TIE = 'png/tie.png',
  MESSAGES = 'png/messages.png',
  MASKED_CARD = 'png/masked_card.png',
  DUMMY_LAPTOP = 'png/DUMMY_LAPTOP.png',
  DUMMY_BLOG = 'png/DUMMY_BLOG.png',
  FULL_LOGO_HORIZONTAL = 'logo/full-logo-horizontal.png',
  FULL_LOGO_HORIZONTAL_INVERSE = 'logo/inverse-logo.png',
  FULL_LOGO_VERTICAL_LIGHT = 'logo/full-logo-vertical-light.png',
  FULL_LOGO_VERTICAL = 'logo/full-logo-vertical.png',
  LOGO_HORIZONTAL = 'logo/logo-horizontal.png',
  MINI_LOGO = 'logo/mini-logo.png'
}

export const getImagePath = (image: ImageNames) => {
  return `${ASSETS_PATH}${image}`;
};

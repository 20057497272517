import { EventHandler, useEffect } from 'react';
import styles from './lightBoxContainer.module.css';
import { WhiteCard } from 'src/components/whiteCard';
import { Typography } from 'src/components/typography';
import { useMediaQueryVariables } from 'src/hooks/useMediaQuery';

export type LightboxContainerProps = {
  children: any;
  show: boolean;
  onCloseClick: EventHandler<any>;
  title: string;
};

const LightboxContainer = (props: LightboxContainerProps) => {
  const { isTabletOrMobile } = useMediaQueryVariables();
  useEffect(() => {
    if (props.show) {
      //document.body.scrollTop = 0;
      //document.documentElement.scrollTop = 0;
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'scroll';
  }, [props.show]);

  return (
    <div className={props.show ? styles.lightboxContainer : `${styles.lightboxContainer} ${styles.hide}`}>
      <WhiteCard style={isTabletOrMobile ? { width: '90%' } : { width: '60%' }}>
        <div style={{ height: '100%', overflow: 'scroll' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography size={32} fontFamily="Medium">
              {props.title}
            </Typography>
            <div
              className={styles.closeButton}
              onClick={() => {
                if (props.onCloseClick) props.onCloseClick(!props.show);
              }}
            >
              X
            </div>
          </div>
          <div style={{ overflow: 'auto', maxHeight: '78vh', paddingRight: '14px' }}>{props.children}</div>
        </div>
      </WhiteCard>
    </div>
  );
};

export default LightboxContainer;

/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationFormCreateModel,
  ApplicationFormViewModelPagedDataModelValidatedModel,
  ErrorModel,
  SearchModel,
} from '../models';
import {
    ApplicationFormCreateModelFromJSON,
    ApplicationFormCreateModelToJSON,
    ApplicationFormViewModelPagedDataModelValidatedModelFromJSON,
    ApplicationFormViewModelPagedDataModelValidatedModelToJSON,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    SearchModelFromJSON,
    SearchModelToJSON,
} from '../models';

export interface ApplicationFormAllPostRequest {
    searchModel?: SearchModel;
}

export interface ApplicationFormApplyPostRequest {
    applicationFormCreateModel?: ApplicationFormCreateModel;
}

/**
 * 
 */
export class ApplicationFormApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async applicationFormAllPostRaw(requestParameters: ApplicationFormAllPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationFormViewModelPagedDataModelValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application-form/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchModelToJSON(requestParameters.searchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationFormViewModelPagedDataModelValidatedModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async applicationFormAllPost(requestParameters: ApplicationFormAllPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationFormViewModelPagedDataModelValidatedModel> {
        const response = await this.applicationFormAllPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async applicationFormApplyPostRaw(requestParameters: ApplicationFormApplyPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/application-form/apply`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationFormCreateModelToJSON(requestParameters.applicationFormCreateModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async applicationFormApplyPost(requestParameters: ApplicationFormApplyPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.applicationFormApplyPostRaw(requestParameters, initOverrides);
    }

}

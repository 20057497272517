/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipmentGroupListModel } from './EquipmentGroupListModel';
import {
    EquipmentGroupListModelFromJSON,
    EquipmentGroupListModelFromJSONTyped,
    EquipmentGroupListModelToJSON,
} from './EquipmentGroupListModel';
import type { ErrorModel } from './ErrorModel';
import {
    ErrorModelFromJSON,
    ErrorModelFromJSONTyped,
    ErrorModelToJSON,
} from './ErrorModel';

/**
 * 
 * @export
 * @interface EquipmentGroupListModelIEnumerableValidatedModel
 */
export interface EquipmentGroupListModelIEnumerableValidatedModel {
    /**
     * 
     * @type {ErrorModel}
     * @memberof EquipmentGroupListModelIEnumerableValidatedModel
     */
    error?: ErrorModel;
    /**
     * 
     * @type {boolean}
     * @memberof EquipmentGroupListModelIEnumerableValidatedModel
     */
    readonly success?: boolean;
    /**
     * 
     * @type {Array<EquipmentGroupListModel>}
     * @memberof EquipmentGroupListModelIEnumerableValidatedModel
     */
    data?: Array<EquipmentGroupListModel> | null;
}

/**
 * Check if a given object implements the EquipmentGroupListModelIEnumerableValidatedModel interface.
 */
export function instanceOfEquipmentGroupListModelIEnumerableValidatedModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EquipmentGroupListModelIEnumerableValidatedModelFromJSON(json: any): EquipmentGroupListModelIEnumerableValidatedModel {
    return EquipmentGroupListModelIEnumerableValidatedModelFromJSONTyped(json, false);
}

export function EquipmentGroupListModelIEnumerableValidatedModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentGroupListModelIEnumerableValidatedModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': !exists(json, 'error') ? undefined : ErrorModelFromJSON(json['error']),
        'success': !exists(json, 'success') ? undefined : json['success'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(EquipmentGroupListModelFromJSON)),
    };
}

export function EquipmentGroupListModelIEnumerableValidatedModelToJSON(value?: EquipmentGroupListModelIEnumerableValidatedModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ErrorModelToJSON(value.error),
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(EquipmentGroupListModelToJSON)),
    };
}


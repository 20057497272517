import _ from 'lodash';
import React from 'react';
import { ViewProps } from '.';
import './view.css';

export const View = (props: ViewProps) => {
  const { style, children, ...remainingProps } = props;

  const getStyle = () => {
    if (Array.isArray(style)) {
      return _.reduce(
        style,
        (memo, item = {}) => {
          return { ...memo, ...item };
        },
        {}
      );
    }

    return style;
  };
  return (
    <div
      className="viewBase"
      style={{
        ...{
          display: 'flex'
        },
        ...getStyle()
      }}
      {...remainingProps}
    >
      {children}
    </div>
  );
};

export const toUpperCase = (value: string = '') => {
  return value.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

export const toDialFormat = (value: string = '') => {
  const withoutWhitepsaceValue = value.replace(/\s/g, '');

  return `tel:${withoutWhitepsaceValue}`;
};

export const toMailFormat = (value: string = '') => {
  const withoutWhitepsaceValue = value.replace(/\s/g, '');
  return `mailto:${withoutWhitepsaceValue}`;
};

export const allowOnlyDecimals = (input: string) => {
  // eslint-disable-next-line no-useless-escape
  if (input === '0') return input;
  if (input.length === 2 && input[0] === '0') {
    if (input === '00') return '0';
    input = input[1].toString();
  }
  // eslint-disable-next-line no-useless-escape
  const onlyDecimals = new RegExp(/^[1-9][\,\d]*(,\d+)?$/g);
  if (input !== '' && !onlyDecimals.test(input)) {
    input = input.slice(0, -1);
  }
  if (input.split(',').length > 2) input = input.slice(0, -1);
  return input;
};

export const addThousandSeperatorAndCheckDecimals = (input: string) => {
  input = input.replaceAll('.', '');
  input = allowOnlyDecimals(input);
  var partsOfInput = input.split(',');
  var integerPartOfInput = partsOfInput[0];
  var decimalPartOfInput = partsOfInput[1];
  integerPartOfInput = integerPartOfInput.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  if (decimalPartOfInput !== undefined) input = integerPartOfInput + ',' + decimalPartOfInput;
  else input = integerPartOfInput;
  return input;
};

/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BasicModelIEnumerableValidatedModel,
  EquipmentGroupIEnumerableValidatedModel,
  ErrorModel,
} from '../models';
import {
    BasicModelIEnumerableValidatedModelFromJSON,
    BasicModelIEnumerableValidatedModelToJSON,
    EquipmentGroupIEnumerableValidatedModelFromJSON,
    EquipmentGroupIEnumerableValidatedModelToJSON,
    ErrorModelFromJSON,
    ErrorModelToJSON,
} from '../models';

export interface EquipmentEquipmentIdSellersGetRequest {
    equipmentId: number;
}

export interface EquipmentGuestAllIdGetRequest {
    id: number;
}

/**
 * 
 */
export class EquipmentApi extends runtime.BaseAPI {

    /**
     */
    async equipmentAllGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EquipmentGroupIEnumerableValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EquipmentGroupIEnumerableValidatedModelFromJSON(jsonValue));
    }

    /**
     */
    async equipmentAllGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EquipmentGroupIEnumerableValidatedModel> {
        const response = await this.equipmentAllGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Guest
     */
    async equipmentEquipmentIdSellersGetRaw(requestParameters: EquipmentEquipmentIdSellersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasicModelIEnumerableValidatedModel>> {
        if (requestParameters.equipmentId === null || requestParameters.equipmentId === undefined) {
            throw new runtime.RequiredError('equipmentId','Required parameter requestParameters.equipmentId was null or undefined when calling equipmentEquipmentIdSellersGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/{equipmentId}/sellers`.replace(`{${"equipmentId"}}`, encodeURIComponent(String(requestParameters.equipmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasicModelIEnumerableValidatedModelFromJSON(jsonValue));
    }

    /**
     * Guest
     */
    async equipmentEquipmentIdSellersGet(requestParameters: EquipmentEquipmentIdSellersGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasicModelIEnumerableValidatedModel> {
        const response = await this.equipmentEquipmentIdSellersGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Guest
     */
    async equipmentGuestAllIdGetRaw(requestParameters: EquipmentGuestAllIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasicModelIEnumerableValidatedModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling equipmentGuestAllIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/equipment/guest-all/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasicModelIEnumerableValidatedModelFromJSON(jsonValue));
    }

    /**
     * Guest
     */
    async equipmentGuestAllIdGet(requestParameters: EquipmentGuestAllIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasicModelIEnumerableValidatedModel> {
        const response = await this.equipmentGuestAllIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

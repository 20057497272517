import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const NotFoundView = () => {
  const navigte = useNavigate();
  useEffect(() => {
    navigte('/');
  }, [navigte]);

  return <div />;
};

import React from 'react';
import { SeeOffersProps } from '.';
import LightboxContainer from '../lightBoxContainer/lightBoxContainer';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Typography } from 'src/components/typography';
import styles from './seeOffers.module.css';
import { UNNAMED_FINANCIAL_COMPANY_LABEL } from 'src/utils/constants';

export const SeeOffers = (props: SeeOffersProps) => {
  const { model, show, onCloseClick } = props;
  const { t } = useTranslation();

  return (
    <LightboxContainer title={''} show={show} onCloseClick={onCloseClick}>
      <Row gutter={[16, 16]}>
        <Col xs={8}>
          <Typography fontFamily="Medium" color="color8">
            {t('dashboard.modal.equipmentValue')}
          </Typography>
          <div className={styles.grayBox}>
            {model?.offers?.[0].equipmentValue?.toLocaleString('tr-TR', { minimumFractionDigits: 2 }) + ' ' + model?.offers?.[0].currency?.name}
          </div>
        </Col>
        <Col xs={8}>
          <Typography fontFamily="Medium" color="color8">
            {t('dashboard.modal.payment')}
          </Typography>
          <div className={styles.grayBox}>
            {model?.offers?.[0].payment?.toLocaleString('tr-TR', { minimumFractionDigits: 2 }) + ' ' + model?.offers?.[0].currency?.name}
          </div>
        </Col>
        <Col xs={8}>
          <Typography fontFamily="Medium" color="color8">
            {t('dashboard.modal.maturityOption')}
          </Typography>
          <div className={styles.grayBox}>{model?.offers?.[0].maturityOption?.name}</div>
        </Col>
        <Col xs={24}>
          <Typography size={20} fontFamily="Bold">
            {t('dashboard.modal.offersTitle')}
          </Typography>
        </Col>
        {model?.offers?.map((offer) => {
          return (
            <Col xs={24}>
              <div style={{ backgroundColor: offer.isAccepted ? '#35B934' : '#FFFFFF', borderRadius: '16px', paddingLeft: '10px', margin: '2px' }}>
                <Row style={{ backgroundColor: 'white', borderRadius: '12px', padding: '16px' }} gutter={[8, 8]}>
                  <Col xs={12}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography fontFamily="Bold" size={16}>
                        {offer.financialCompany?.name || UNNAMED_FINANCIAL_COMPANY_LABEL + '/ ' + offer.financialCompany?.id}
                      </Typography>
                      <div>
                        <Typography fontFamily="Medium" size={16}>
                          {t('dashboard.modal.totalPayment')}
                        </Typography>
                        <Typography fontFamily="Bold" size={16}>
                          {' '}
                          {offer.totalPaymentValue?.toLocaleString('tr-TR', { minimumFractionDigits: 2 }) + ' ' + offer.currency?.name}
                        </Typography>
                      </div>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography fontFamily="SemiBold" size={16}>
                        {' '}
                        {offer.equipmentValue?.toLocaleString('tr-TR', { minimumFractionDigits: 2 }) + ' ' + offer.currency?.name}
                      </Typography>
                      <Typography color="color8" fontFamily="Medium" size={14}>
                        {t('dashboard.modal.equipmentValue')}
                      </Typography>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography fontFamily="SemiBold" size={16}>
                        {' '}
                        {offer.monthlyPaymentValue?.toLocaleString('tr-TR', { minimumFractionDigits: 2 }) + ' ' + offer.currency?.name}
                      </Typography>
                      <Typography color="color8" fontFamily="Medium" size={14}>
                        {t('dashboard.modal.monthlyPayment')}
                      </Typography>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <Typography fontFamily="SemiBold" size={16}>
                        {' '}
                        {offer.maturityOption?.name}
                      </Typography>
                      <Typography color="color8" fontFamily="Medium" size={14}>
                        {t('dashboard.modal.maturityOption')}
                      </Typography>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </LightboxContainer>
  );
};

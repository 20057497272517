import { UseStyleVariables } from 'src/hooks/useStyles';
import { bgColor, layoutStyles, padding } from 'src/styles/layoutStyles';

export const baseStyles = (useStyleVariables: UseStyleVariables) => {
  const { theme, mediaQueryVariables } = useStyleVariables;
  const { isTabletOrMobile } = mediaQueryVariables;
  return {
    contentContainer: {
      ...bgColor(theme.colors.color5)
    },
    container: [padding(isTabletOrMobile ? 0 : 40, 'horizontal'), padding(8, 'vertical'), layoutStyles.center],
    topContent: {
      ...layoutStyles.column,
      ...layoutStyles.center,
      ...padding(isTabletOrMobile ? 6 : 40, 'horizontal')
    }
  };
};

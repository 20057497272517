/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RateCreateModel } from './RateCreateModel';
import {
    RateCreateModelFromJSON,
    RateCreateModelFromJSONTyped,
    RateCreateModelToJSON,
} from './RateCreateModel';

/**
 * 
 * @export
 * @interface FinancialCompanyUpdateModel
 */
export interface FinancialCompanyUpdateModel {
    /**
     * 
     * @type {number}
     * @memberof FinancialCompanyUpdateModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FinancialCompanyUpdateModel
     */
    name?: string | null;
    /**
     * 
     * @type {Array<RateCreateModel>}
     * @memberof FinancialCompanyUpdateModel
     */
    rates?: Array<RateCreateModel> | null;
}

/**
 * Check if a given object implements the FinancialCompanyUpdateModel interface.
 */
export function instanceOfFinancialCompanyUpdateModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FinancialCompanyUpdateModelFromJSON(json: any): FinancialCompanyUpdateModel {
    return FinancialCompanyUpdateModelFromJSONTyped(json, false);
}

export function FinancialCompanyUpdateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialCompanyUpdateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'rates': !exists(json, 'rates') ? undefined : (json['rates'] === null ? null : (json['rates'] as Array<any>).map(RateCreateModelFromJSON)),
    };
}

export function FinancialCompanyUpdateModelToJSON(value?: FinancialCompanyUpdateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'rates': value.rates === undefined ? undefined : (value.rates === null ? null : (value.rates as Array<any>).map(RateCreateModelToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationFormViewModel } from './ApplicationFormViewModel';
import {
    ApplicationFormViewModelFromJSON,
    ApplicationFormViewModelFromJSONTyped,
    ApplicationFormViewModelToJSON,
} from './ApplicationFormViewModel';

/**
 * 
 * @export
 * @interface ApplicationFormViewModelPagedDataModel
 */
export interface ApplicationFormViewModelPagedDataModel {
    /**
     * 
     * @type {number}
     * @memberof ApplicationFormViewModelPagedDataModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationFormViewModelPagedDataModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationFormViewModelPagedDataModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationFormViewModelPagedDataModel
     */
    readonly pageCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationFormViewModelPagedDataModel
     */
    itemCount?: number;
    /**
     * 
     * @type {Array<ApplicationFormViewModel>}
     * @memberof ApplicationFormViewModelPagedDataModel
     */
    data?: Array<ApplicationFormViewModel> | null;
}

/**
 * Check if a given object implements the ApplicationFormViewModelPagedDataModel interface.
 */
export function instanceOfApplicationFormViewModelPagedDataModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationFormViewModelPagedDataModelFromJSON(json: any): ApplicationFormViewModelPagedDataModel {
    return ApplicationFormViewModelPagedDataModelFromJSONTyped(json, false);
}

export function ApplicationFormViewModelPagedDataModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationFormViewModelPagedDataModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'pageCount': !exists(json, 'pageCount') ? undefined : json['pageCount'],
        'itemCount': !exists(json, 'itemCount') ? undefined : json['itemCount'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(ApplicationFormViewModelFromJSON)),
    };
}

export function ApplicationFormViewModelPagedDataModelToJSON(value?: ApplicationFormViewModelPagedDataModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'itemCount': value.itemCount,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(ApplicationFormViewModelToJSON)),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorModel,
  FinancialCompanyByConversionModelPagedDataModelValidatedModel,
  FinancialCompanyCreateModel,
  FinancialCompanyModelPagedDataModelValidatedModel,
  FinancialCompanyUpdateModel,
  SearchModel,
} from '../models';
import {
    ErrorModelFromJSON,
    ErrorModelToJSON,
    FinancialCompanyByConversionModelPagedDataModelValidatedModelFromJSON,
    FinancialCompanyByConversionModelPagedDataModelValidatedModelToJSON,
    FinancialCompanyCreateModelFromJSON,
    FinancialCompanyCreateModelToJSON,
    FinancialCompanyModelPagedDataModelValidatedModelFromJSON,
    FinancialCompanyModelPagedDataModelValidatedModelToJSON,
    FinancialCompanyUpdateModelFromJSON,
    FinancialCompanyUpdateModelToJSON,
    SearchModelFromJSON,
    SearchModelToJSON,
} from '../models';

export interface FinancialCompanyAllByConversionsPostRequest {
    searchModel?: SearchModel;
}

export interface FinancialCompanyAllPostRequest {
    searchModel?: SearchModel;
}

export interface FinancialCompanyCreatePostRequest {
    financialCompanyCreateModel?: FinancialCompanyCreateModel;
}

export interface FinancialCompanyToggleStatusCompanyIdPutRequest {
    companyId: number;
}

export interface FinancialCompanyUpdatePutRequest {
    financialCompanyUpdateModel?: FinancialCompanyUpdateModel;
}

/**
 * 
 */
export class FinancialCompanyApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async financialCompanyAllByConversionsPostRaw(requestParameters: FinancialCompanyAllByConversionsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FinancialCompanyByConversionModelPagedDataModelValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/financial-company/all-by-conversions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchModelToJSON(requestParameters.searchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FinancialCompanyByConversionModelPagedDataModelValidatedModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async financialCompanyAllByConversionsPost(requestParameters: FinancialCompanyAllByConversionsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FinancialCompanyByConversionModelPagedDataModelValidatedModel> {
        const response = await this.financialCompanyAllByConversionsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async financialCompanyAllPostRaw(requestParameters: FinancialCompanyAllPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FinancialCompanyModelPagedDataModelValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/financial-company/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchModelToJSON(requestParameters.searchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FinancialCompanyModelPagedDataModelValidatedModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async financialCompanyAllPost(requestParameters: FinancialCompanyAllPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FinancialCompanyModelPagedDataModelValidatedModel> {
        const response = await this.financialCompanyAllPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async financialCompanyCreatePostRaw(requestParameters: FinancialCompanyCreatePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/financial-company/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FinancialCompanyCreateModelToJSON(requestParameters.financialCompanyCreateModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async financialCompanyCreatePost(requestParameters: FinancialCompanyCreatePostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.financialCompanyCreatePostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin
     */
    async financialCompanyToggleStatusCompanyIdPutRaw(requestParameters: FinancialCompanyToggleStatusCompanyIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling financialCompanyToggleStatusCompanyIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/financial-company/toggle-status/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async financialCompanyToggleStatusCompanyIdPut(requestParameters: FinancialCompanyToggleStatusCompanyIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.financialCompanyToggleStatusCompanyIdPutRaw(requestParameters, initOverrides);
    }

    /**
     * Admin
     */
    async financialCompanyUpdatePutRaw(requestParameters: FinancialCompanyUpdatePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/financial-company/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FinancialCompanyUpdateModelToJSON(requestParameters.financialCompanyUpdateModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async financialCompanyUpdatePut(requestParameters: FinancialCompanyUpdatePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.financialCompanyUpdatePutRaw(requestParameters, initOverrides);
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * GGLease API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BasicModel } from './BasicModel';
import {
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import type { OfferViewModel } from './OfferViewModel';
import {
    OfferViewModelFromJSON,
    OfferViewModelFromJSONTyped,
    OfferViewModelToJSON,
} from './OfferViewModel';

/**
 * 
 * @export
 * @interface ApplicationFormViewModel
 */
export interface ApplicationFormViewModel {
    /**
     * 
     * @type {Date}
     * @memberof ApplicationFormViewModel
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationFormViewModel
     */
    updatedOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof ApplicationFormViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormViewModel
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormViewModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormViewModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormViewModel
     */
    vkn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormViewModel
     */
    company?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormViewModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationFormViewModel
     */
    sellerCompanyOther?: string | null;
    /**
     * 
     * @type {BasicModel}
     * @memberof ApplicationFormViewModel
     */
    sellerCompany?: BasicModel;
    /**
     * 
     * @type {BasicModel}
     * @memberof ApplicationFormViewModel
     */
    equipment?: BasicModel;
    /**
     * 
     * @type {BasicModel}
     * @memberof ApplicationFormViewModel
     */
    equipmentGroup?: BasicModel;
    /**
     * 
     * @type {Array<OfferViewModel>}
     * @memberof ApplicationFormViewModel
     */
    offers?: Array<OfferViewModel> | null;
}

/**
 * Check if a given object implements the ApplicationFormViewModel interface.
 */
export function instanceOfApplicationFormViewModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationFormViewModelFromJSON(json: any): ApplicationFormViewModel {
    return ApplicationFormViewModelFromJSONTyped(json, false);
}

export function ApplicationFormViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationFormViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdOn': !exists(json, 'createdOn') ? undefined : (new Date(json['createdOn'])),
        'updatedOn': !exists(json, 'updatedOn') ? undefined : (new Date(json['updatedOn'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'vkn': !exists(json, 'vkn') ? undefined : json['vkn'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'sellerCompanyOther': !exists(json, 'sellerCompanyOther') ? undefined : json['sellerCompanyOther'],
        'sellerCompany': !exists(json, 'sellerCompany') ? undefined : BasicModelFromJSON(json['sellerCompany']),
        'equipment': !exists(json, 'equipment') ? undefined : BasicModelFromJSON(json['equipment']),
        'equipmentGroup': !exists(json, 'equipmentGroup') ? undefined : BasicModelFromJSON(json['equipmentGroup']),
        'offers': !exists(json, 'offers') ? undefined : (json['offers'] === null ? null : (json['offers'] as Array<any>).map(OfferViewModelFromJSON)),
    };
}

export function ApplicationFormViewModelToJSON(value?: ApplicationFormViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdOn': value.createdOn === undefined ? undefined : (value.createdOn.toISOString()),
        'updatedOn': value.updatedOn === undefined ? undefined : (value.updatedOn.toISOString()),
        'id': value.id,
        'fullName': value.fullName,
        'email': value.email,
        'phone': value.phone,
        'vkn': value.vkn,
        'company': value.company,
        'description': value.description,
        'sellerCompanyOther': value.sellerCompanyOther,
        'sellerCompany': BasicModelToJSON(value.sellerCompany),
        'equipment': BasicModelToJSON(value.equipment),
        'equipmentGroup': BasicModelToJSON(value.equipmentGroup),
        'offers': value.offers === undefined ? undefined : (value.offers === null ? null : (value.offers as Array<any>).map(OfferViewModelToJSON)),
    };
}


import React from 'react';
import { WhiteCardProps } from '.';

export const WhiteCard = (props: WhiteCardProps) => {
  const { children, style } = props;

  return (
    <div
      style={{
        background: '#F8F9FA',
        boxShadow: '0px 4px 52px rgba(26, 40, 69, 0.1)',
        borderRadius: '20px',
        padding: '26px',
        minWidth: '40%',
        minHeight: '40%',
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexDirection: 'column',
        gap: '32px',
        margin: '8px',
        ...style
      }}
    >
      {children}
    </div>
  );
};

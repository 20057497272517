import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
//import { useMediaQueryVariables } from "src/hooks/useMediaQuery";
import { Typography } from 'src/components/typography';
import { WhiteCard } from 'src/components/whiteCard';
import { loadingAtom } from 'src/utils/atoms/global-atoms';
import { useAtom } from 'jotai';
import { AdminContainer } from 'src/components/adminContainer';
import { PaginationType, useSecureService } from 'src/hooks/service-hooks';
import { FinancialCompanyByConversionModel, FinancialCompanyModel } from 'src/service';
import { TablePaginationConfig } from 'antd';
import DataTable from 'src/components/dataTable/data-table';
import { Button } from 'src/components/button';
import { Data } from 'react-csv/components/CommonPropTypes';
import { CSVLink } from 'react-csv';
import { UNNAMED_FINANCIAL_COMPANY_LABEL } from 'src/utils/constants';

export const ReportsView = () => {
  //const { isTabletOrMobile } = useMediaQueryVariables();
  const { t } = useTranslation();
  const [loading] = useAtom(loadingAtom);
  const [financialCompanies, setFinancialCompanies] = useState<FinancialCompanyByConversionModel[] | undefined>(undefined);
  const [allRows, setAllRows] = useState<Data>([]);
  const [callAgain, setCallAgain] = useState(0);
  const [itemCount, setItemCount] = useState<number | undefined>(1);
  const [pagination, setPagination] = useState<PaginationType>({
    current: 1,
    pageSize: 10
  });
  const initialColumnsArr = [
    {
      title: t('reportsView.table.name'),
      dataIndex: 'name',
      key: 'name',
      render: (val: string, row: FinancialCompanyModel) => <>{val ? val : UNNAMED_FINANCIAL_COMPANY_LABEL + '/ ' + row.id}</>
    },
    {
      title: t('reportsView.table.TotalView'),
      dataIndex: 'totalView',
      key: 'totalView'
    },
    {
      title: t('reportsView.table.conversions'),
      dataIndex: 'totalConversions',
      key: 'totalConversions',
      render: (val: number) => {
        return (
          <div
            style={{
              backgroundColor: '#35B934',
              padding: '4px',
              borderRadius: '24px',
              display: 'flex',
              maxWidth: '80px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography color="color1">{val}</Typography>
          </div>
        );
      }
    }
  ];
  const { doGetAllFinancialCompaniesByConversion } = useSecureService();
  const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const headers = [
    { label: 'name', key: 'name' },
    { label: t('reportsView.table.TotalView'), key: 'totalView' },
    { label: t('reportsView.table.conversions'), key: 'totalConversions' }
  ];

  useEffect(() => {
    const getAllFinancialCompaniesByConversion = async () => {
      const result = await doGetAllFinancialCompaniesByConversion({
        pageSize: pagination.pageSize,
        pageNo: pagination.current
      });
      setItemCount(result?.itemCount);
      setFinancialCompanies(result?.data || undefined);
    };
    getAllFinancialCompaniesByConversion();
    //eslint-disable-next-line
  }, [callAgain]);

  useEffect(() => {
    if (!allRows || allRows.length === 0) return;
    csvRef.current?.link.click();
  }, [allRows]);

  const handleChange = (tablePagination: TablePaginationConfig) => {
    setPagination((curr) => {
      return { ...curr, current: tablePagination.current };
    });
    setCallAgain((val) => val + 1);
  };

  const exportClick = async () => {
    if (!itemCount || itemCount <= 0) {
      return;
    }
    const result = await doGetAllFinancialCompaniesByConversion({ pageNo: 1, pageSize: itemCount });
    if (result?.data?.length !== undefined && result?.data?.length > 0) {
      let manipulatedData: Data = [];
      result.data.forEach((item: FinancialCompanyByConversionModel) => {
        const newItem: any = item;
        newItem.id = item.id;
        newItem.name = item.name ? item.name : UNNAMED_FINANCIAL_COMPANY_LABEL + '/ ' + item.id;
        newItem.isActive = item.isActive;
        manipulatedData.push(newItem);
      });

      setAllRows(manipulatedData);
    }
  };

  return (
    <AdminContainer activeNode={'/reports'}>
      <WhiteCard>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography size={20} fontFamily="SemiBold">
            {t('reportsView.page.title')}
          </Typography>
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button onPress={exportClick} label={t('common.export-as-csv')} />
          </div>
        </div>
        <CSVLink filename="raporlar.csv" ref={csvRef} data={allRows} headers={headers} separator={';'} />
        <DataTable
          onChange={handleChange}
          columns={initialColumnsArr}
          dataSource={financialCompanies}
          loading={loading}
          pagination={{ ...pagination, position: ['bottomCenter'], total: itemCount, showSizeChanger: false }}
        />
      </WhiteCard>
    </AdminContainer>
  );
};

import React, { CSSProperties, useMemo } from 'react';
import { TypographyProps } from '.';
import _ from 'lodash';
import { useTheme } from 'src/hooks/useTheme';

export const Typography = (props: TypographyProps) => {
  const theme = useTheme();
  const { color = 'color5', fontFamily = 'Regular', size, isCenter, isLeft, isRight, children, lineHeight, style, ...remainingProps } = props;

  const allStyles = useMemo<CSSProperties>(() => {
    let _allStyles: CSSProperties = {
      fontSize: size,
      fontFamily,
      color: theme.colors[color]
    };

    if (lineHeight) {
      _allStyles = _.merge(_allStyles, {
        lineHeight: `${lineHeight}px`
      });
    }

    if (isCenter) {
      _allStyles = _.merge(_allStyles, {
        textAlign: 'center'
      });
    }

    if (isLeft) {
      _allStyles = _.merge(_allStyles, {
        textAlign: 'left'
      });
    }

    if (isRight) {
      _allStyles = _.merge(_allStyles, {
        textAlign: 'right'
      });
    }
    return _.merge(_allStyles, style);
    //eslint-disable-next-line
  }, [color, fontFamily, isCenter, lineHeight, size, style, theme.colors]);

  return (
    <span style={allStyles} {...remainingProps}>
      {children}
    </span>
  );
};

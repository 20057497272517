import { useAtom } from 'jotai';
import React, { ReactElement } from 'react';
import { Footer } from 'src/components/footer';
import { layoutStyles } from 'src/styles/layoutStyles';
import { menuAtom } from 'src/utils/atoms/menuAtom';
import { Header } from '../header';
import { Menu } from '../menu';
import { View } from '../view';

export const Container = (props: { children?: ReactElement | ReactElement[]; hideFooter?: boolean }) => {
  const [isMenuOpen] = useAtom(menuAtom);
  const { children } = props;

  return (
    <>
      <View style={[layoutStyles.fullFlex, layoutStyles.column, { display: isMenuOpen ? 'none' : 'flex' }]}>
        <Header />
        <View style={[layoutStyles.fullFlex, layoutStyles.column]}>{children}</View>
        {!props.hideFooter && <Footer />}
      </View>
      {isMenuOpen && <Menu />}
    </>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getImagePath, ImageNames } from 'src/utils/helper/image';

export const HeaederLogo = (props: { inverse?: boolean }) => {
  const navigete = useNavigate();
  const logoPath = props.inverse ? ImageNames.FULL_LOGO_HORIZONTAL_INVERSE : ImageNames.FULL_LOGO_HORIZONTAL;
  return (
    <img
      onClick={() => navigete('/')}
      alt="logo-vertical"
      src={getImagePath(logoPath)}
      style={{
        cursor: 'pointer',
        width: 191
      }}
    />
  );
};

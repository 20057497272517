import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { View } from 'src/components/view';
import { useStyle } from 'src/hooks/useStyles';
import { useTheme } from 'src/hooks/useTheme';
import { layoutStyles, margin } from 'src/styles/layoutStyles';
import { toDialFormat, toMailFormat } from 'src/utils/string';
import { ContentContainer } from '../contentContainer';
import { Icon } from '../icon';
import { LayoutContainer } from '../layoutContainer';
import { Typography } from '../typography';
import { FooterLogo } from './footer.logo';
import { baseStyles } from './footer.styles';
import { Col, Row } from 'antd';
import LightboxContainer from '../modals/lightBoxContainer/lightBoxContainer';

export const Footer = () => {
  const { t } = useTranslation();
  const styles = useStyle(baseStyles);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const menuLabels = [
    {
      label: 'menu.about-us',
      to: '/about-us'
    },
    {
      label: 'menu.blog',
      to: '/blog'
    },
    {
      label: 'menu.contact-us',
      to: '/#contact-form'
    }
  ];
  const theme = useTheme();

  const renderLabel = (item: { label: string; to: string; modal?: boolean; title?: string }, index: number) => {
    return (
      <Col key={index} xs={12} md={8} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {item.modal ? (
          <>
            <Typography
              color={'color1'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const title = t(item.title || '');
                setModalTitle(title);
                setModalContent(item.to);
                setModalVisible(true);
              }}
            >
              {t(item.label)}
            </Typography>
          </>
        ) : (
          <HashLink
            smooth
            to={item.to}
            style={{
              textDecoration: 'none'
            }}
          >
            <Typography color={'color1'}>{t(item.label)}</Typography>
          </HashLink>
        )}
      </Col>
    );
  };
  return (
    <LayoutContainer>
      <ContentContainer style={styles.contentContainer}>
        <View style={styles.container}>
          <View style={[layoutStyles.column, layoutStyles.alignCenter]}>
            <View style={{ ...styles.topContent, padding: '0' }}>
              <FooterLogo />
              <View style={margin(6, 'top')}>
                <Row gutter={[8, 8]}>{menuLabels.map(renderLabel)}</Row>
              </View>
              <View style={[layoutStyles.spaceBetween, layoutStyles.row, layoutStyles.justifyCenter]}>
                <a style={{ ...margin(6, 'top'), ...margin(3, 'right'), ...layoutStyles.justifyCenter }} href={toDialFormat(t('contact.phone') as string)}>
                  <Icon icon="phone" style={margin(2, 'right')} color={theme.colors.color1} />
                  <Typography color={'color1'}>{t('contact.phone')}</Typography>
                </a>

                <a style={{ ...margin(6, 'top'), ...margin(3, 'left'), ...layoutStyles.justifyCenter }} href={toMailFormat(t('contact.email') as string)}>
                  <Icon icon="mail" style={margin(2, 'right')} color={theme.colors.color1} />
                  <Typography color={'color1'}>{t('contact.email')}</Typography>
                </a>
              </View>

              <View style={[margin(4, 'top')]}>
                <View>
                  <Icon icon="location" color={theme.colors.color1} size={20} style={margin(2, 'right')} />
                </View>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  <Typography isCenter size={16} fontFamily={'Medium'} color={'color1'}>
                    {t('contact.firmName')}
                  </Typography>
                  <Typography isCenter size={16} fontFamily={'Medium'} color={'color1'}>
                    {t('contact.address')}
                  </Typography>
                </div>
              </View>
            </View>

            <View
              style={{
                borderTop: `1px solid ${theme.colors.color1}`,
                marginTop: 16,
                width: '100%',
                ...layoutStyles.center
              }}
            >
              <Typography isCenter color={'color1'} style={margin(4, 'top')}>
                {t('footer.info')}
              </Typography>
            </View>
          </View>
        </View>
        <LightboxContainer
          show={modalVisible}
          onCloseClick={(event: any) => {
            setModalVisible(false);
          }}
          title={modalTitle}
        >
          <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
        </LightboxContainer>
      </ContentContainer>
    </LayoutContainer>
  );
};

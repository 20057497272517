import { Theme } from 'src/types/theme';
import { MediaQueryVariables, useMediaQueryVariables } from './useMediaQuery';
import { useTheme } from './useTheme';
import useWindowDimensions from './useWindowDimensions';

export type UseStyleVariables = {
  theme: Theme;
  mediaQueryVariables: MediaQueryVariables;
  windowDimensions: ReturnType<typeof useWindowDimensions>;
};

function useStyle<T>(style: (useStyleVariables: UseStyleVariables) => T): T {
  const theme = useTheme();
  const mediaQueryVariables = useMediaQueryVariables();
  const windowDimensions = useWindowDimensions();
  return style({ theme, mediaQueryVariables, windowDimensions }) as T;
}

export { useStyle };

import React, { useMemo } from 'react';
import { useMediaQueryVariables } from 'src/hooks/useMediaQuery';
import { useStyle } from 'src/hooks/useStyles';
import { ContentContainer } from '../contentContainer';
import { LayoutContainer } from '../layoutContainer';
import { HeaderDesktop } from './header.desktop';
import { HeaderMobile } from './header.mobile';
import { baseStyles } from './header.styles';

export const Header = () => {
  const styles = useStyle(baseStyles);
  const { isTabletOrMobile } = useMediaQueryVariables();

  const HeaderComponent = useMemo(() => {
    if (isTabletOrMobile) {
      return HeaderMobile;
    }

    return HeaderDesktop;
  }, [isTabletOrMobile]);

  return (
    <LayoutContainer>
      <ContentContainer style={styles.contentContainer}>
        <HeaderComponent />
      </ContentContainer>
    </LayoutContainer>
  );
};

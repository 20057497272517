import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorTextProps } from '.';
import { Typography } from '../typography';

export const ErrorText = (props: ErrorTextProps) => {
  const { errorMessage, style } = props;
  const { t } = useTranslation();
  return (
    <Typography style={style} color="color9" size={12}>
      {t(errorMessage || '')}
    </Typography>
  );
};

import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { UserDetailModel, UserLoginModel } from 'src/service';

export type ToastMessage = {
  message: string;
  type: 'info' | 'error' | 'warning' | 'success' | 'loading';
};
export const loadingAtom = atom(false);
export const messageAtom = atom<ToastMessage>({
  message: '',
  type: 'info'
});
export const userCredentialsAtom = atom<UserLoginModel | undefined>(undefined);

const userStorage = createJSONStorage<UserDetailModel | undefined>(() => sessionStorage);
export const userAtom = atomWithStorage('user', undefined, userStorage);
export const unauthorizedResponseAtom = atom<string>('');

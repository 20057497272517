import React from 'react';
import { getImagePath, ImageNames } from 'src/utils/helper/image';

export const FooterLogo = () => {
  return (
    <img
      alt="logo-vertical-light"
      src={getImagePath(ImageNames.FULL_LOGO_VERTICAL_LIGHT)}
      style={{
        width: 149
      }}
    />
  );
};

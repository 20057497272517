import { useAtom } from 'jotai';
import React, { ReactElement } from 'react';
import { Layout, Menu } from 'antd';
import { HeaederLogo } from '../header/header.logo';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ImageNames, getImagePath } from 'src/utils/helper/image';
import { Typography } from '../typography';
import { userAtom } from 'src/utils/atoms/global-atoms';
import { Button } from '../button';
import { useSecureService } from 'src/hooks/service-hooks';

export const AdminContainer = (props: {
  children?: ReactElement | ReactElement[];
  activeNode: '/dashboard' | '/reports' | '/seller-management' | '/financial-management';
}) => {
  const { activeNode } = props;
  const { Content, Sider } = Layout;
  const [user] = useAtom(userAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { doLogout } = useSecureService();
  const selectionChangeHandler = async (item: any) => {
    if (item.key === '0') {
      //Logout
    } else {
      navigate(item.key);
    }
  };
  return (
    <Layout>
      <Sider breakpoint="lg" width={240} collapsedWidth="0" style={{ backgroundColor: 'background: #1A2845' }}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div style={{ marginTop: '48px', marginBottom: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <HeaederLogo inverse />
          </div>
          <Menu theme="dark" style={{ height: '100%' }} defaultSelectedKeys={[activeNode]} mode="inline" onSelect={selectionChangeHandler}>
            <Menu.Item key="/dashboard">
              <Typography color="color1">{t('menu.dashboard')}</Typography>
            </Menu.Item>
            <Menu.Item key="/reports">
              <Typography color="color1">{t('menu.reports')}</Typography>
            </Menu.Item>
            <Menu.Item key="/seller-management">
              <Typography color="color1">{t('menu.seller-management')}</Typography>
            </Menu.Item>
            <Menu.Item key="/financial-management" style={{ height: '74px', lineHeight: '24px' }}>
              <Typography color="color1">{t('menu.financial-management')}</Typography>
            </Menu.Item>
          </Menu>
          <div
            style={{
              margin: '12px',
              padding: '16px',
              background: '#1A2845',
              borderRadius: '10px',
              display: 'flex',
              gap: '16px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img alt="profile-vertical" src={getImagePath(ImageNames.PROFILE_IMAGE)} />
            <Typography fontFamily="Bold" color={'color1'}>
              {user?.name}
            </Typography>
            <Button
              onPress={() => {
                doLogout();
                navigate('/');
              }}
              fontFamily="Bold"
              style={{ width: '100%' }}
              label={t('menu.exit')}
            ></Button>
          </div>
        </div>
      </Sider>
      <Content style={{ margin: '16px' }}>{props.children}</Content>
    </Layout>
  );
};
